import React, { useEffect, useRef, useState } from 'react'
import cx from 'classnames'
import { get } from 'lodash'
import styles from './SubmitOverlayMenu.module.scss'
import Modal from 'react-modal'
import Preview from '../../../../../components/Preview'
import SimpleButtonLoader from '../../../../../components/SimpleButtonLoader'
import { hs } from '../../../../../utils/size'
import CloseIcon from '../../../../../assets/Close.js'
import fetchQuizAnswers from '../../../../../queries/fetchQuizAnswers'
import fetchQuizReport from '../../../../../queries/fetchQuizReport'
import fetchUserAssignment from '../../../../../queries/fetchUserAssignment'
import ContentLoader from 'react-content-loader'
import fetchBlockBasedPractice from '../../../../../queries/fetchBlockBasedPractice'
import { isBase64 } from '../../../../../utils/base64Utility'
import isMobile from '../../../../../utils/isMobile'
import checkForEmbed from '../../../../../utils/teacherApp/checkForEmbed'

const getButtonTitle = (path) => {
    if (path === '/homework/:topicId/quiz' || path === '/revisit/homework/:topicId/quiz') {
        return 'Save'
    }

    return 'Confirm'
}

const sortByLoOrderAndQuestionOrder = (question1, question2) => {
    if (question1 && question2) {
        const question1LoOrder = get(question1, 'question.learningObjective.order')
        const question2LoOrder = get(question2, 'question.learningObjective.order')
        if (question1LoOrder === question2LoOrder) {
            return get(question1, 'question.order') - get(question2, 'question.order')
        }
        return question1LoOrder - question2LoOrder
    }
    return null
}

const SubmitOverlay = ({
     visible, onQuizSubmit,message,closeOverlay, title,isLoading,closeImmediately,onSubmitForReview,submitForReviewOverlay,path,disabled, description, isHomeworkComplete = false, userFirstAndLatestQuizReport, userId, topicId, history, courseId, isSuccess, topicComponentRule, setSubmitForReviewClicked
}) => {
    const [quizAnswers, setQuizAnswers] = useState(null)
    const [assignmentQuestions, setAssignmentQuestions] = useState(null)
    const [practiceQuestions, setPracticeQuestions] = useState(null)
    const [components, setComponents] = useState([])
    const [isFetching, setIsFetching] = useState(false)
    const fetchHomeworkDetails = async (bypassCheck = false) => {
        if (isHomeworkComplete && (!isLoading || bypassCheck )) {

          setIsFetching(true);
          const filteredRule = topicComponentRule.filter((el) =>
            ["quiz", "homeworkAssignment", "homeworkPractice"].includes(
              get(el, "componentName")
            )
          );
          if (
            filteredRule.some((el) =>
              ["homeworkPractice"].includes(get(el, "componentName"))
            )
          ) {
            setComponents(
              filteredRule.slice(
                0,
                filteredRule.findIndex(
                  (el) => get(el, "componentName") === "homeworkPractice"
                ) + 1
              )
            );
          } else {
            setComponents(filteredRule);
          }
          await fetchQuizReport(topicId, courseId).call();
          if (userFirstAndLatestQuizReport) {
            const quizReportId = userFirstAndLatestQuizReport.getIn([
              0,
              "latestQuizReport",
              "quizReportId",
            ]);
            if (quizReportId && quizReportId.length > 0) {
              fetchQuizAnswers(quizReportId, null, true, courseId)
                .call()
                .then((res) => {
                  if (res && res.userQuizAnswers) {
                    let quizSortedByOrder = [];
                    if (
                      res.userQuizAnswers.quizAnswers &&
                      res.userQuizAnswers.quizAnswers.length
                    ) {
                      quizSortedByOrder = res.userQuizAnswers.quizAnswers.sort(
                        sortByLoOrderAndQuestionOrder
                      );
                    }
                    setQuizAnswers(quizSortedByOrder);
                    setIsFetching(false);
                  }
                });
            }
          }
          fetchUserAssignment(
            userId,
            topicId,
            "published",
            "withMenteeToken",
            true
          )
            .call()
            .then((res) => {
              if (res && res.userAssignments && res.userAssignments.length) {
                let assignments = get(res.userAssignments[0], "assignment", []);
                assignments = assignments.filter(
                  (el) =>
                    el.assignmentQuestion &&
                    el.assignmentQuestion.isHomework === true
                );
                setAssignmentQuestions(assignments || []);
                setIsFetching(false);
              }
            });
          fetchBlockBasedPractice(userId, null, courseId, null, true, topicId)
            .call()
            .then((res) => {
              if (
                res &&
                res.userBlockBasedPractices &&
                res.userBlockBasedPractices.length
              ) {
                setPracticeQuestions(
                  res.userBlockBasedPractices.filter((el) =>
                    get(el, "blockBasedPractice.isHomework")
                  )
                );
                setIsFetching(false);
              }
            });
        }
    }
    useEffect(() => {
        fetchHomeworkDetails()
    }, [isHomeworkComplete])
    
    useEffect(() => {
        if (visible && isMobile()) {
            fetchHomeworkDetails(true)
        }
    }, [visible])
    useEffect(() => {
        if (!isMobile()) {
            fetchHomeworkDetails()
        }
    }, [isSuccess])

    const checkIfPracticeUnAnswered = (el) => {
        if (get(el, 'blockBasedPractice.isSubmitAnswer')) {
            if ((get(el, 'blockBasedPractice.layout') === 'playground') && !get(el, 'savedBlocks')) {
                return true
            } else if ((get(el, 'blockBasedPractice.layout') === 'fileUpload') && !get(el, 'attachments', []).length) {
                return true
            } else if ((get(el, 'blockBasedPractice.layout') === 'externalPlatform') && !get(el, 'answerLink')) {
                return true
            }
        }
        return false;
    }

    return (
        <Modal
            isOpen={visible}
            className={cx({
                [styles.modalContainer]: true,
                [styles.containerPadding]: !isHomeworkComplete,
                [styles.modalContainerBgImage]: !isHomeworkComplete,
                [styles.modalContainerWidth]: isHomeworkComplete
            })}
            overlayClassName={styles.container}
            closeTimeoutMS={closeImmediately ? 0 : 500}
        >
            <div
                onClick={() => closeOverlay('visibleSubmitOverlay')}
                className={styles.overlayClose}
            >
                <div className={cx(styles.overlayCloseIcon, checkForEmbed() && styles.overlayCloseIconForEmbed)}>
                    <CloseIcon />
                </div>
            </div>
            {isHomeworkComplete ? (
                <>
                    <div className={styles.confirmSubmissionLabel}>
                        Confirm Submission
                    </div>
                    {!isFetching ? (
                        <div className={styles.summaryContainer}>
                            {components.map((component, index) => {
                                if (get(component, 'componentName') === 'quiz') {
                                    return (
                                        <>
                                            {(quizAnswers && quizAnswers.length) ? (
                                                <>
                                                    <span className={styles.summaryTitle}>Section {index+1} : Quiz</span>
                                                    <span className={styles.questionContainer} style={{padding: '6px 4px'}}>
                                                        <span className={styles.headerText}>Name of the questions</span>
                                                        <span className={styles.headerText}>Status</span>
                                                    </span>
                                                    {quizAnswers.map((el,index) => (
                                                        <div className={styles.questionContainer}>
                                                        {
                                                            isBase64(el.question.statement) ? 
                                                            (
                                                                <span className={styles.questionText}>
                                                                   <pre>{index+1}.</pre>
                                                                   <Preview value={el.question.statement} useNativeHtmlParser={true}/>
                                                                </span>
                                                            )
                                                            :
                                                            (<span className={styles.questionText}>{index+1}. <p>{el.question.statement}
                                                                </p></span>)
                                                        }
                                                            <span className={styles.tagContainer}>
                                                                <span className={cx({
                                                                    [styles.answeredTag]: true,
                                                                    [styles.unAnsweredTag]: !el.isAttempted,
                                                                })}>
                                                                    {el.isAttempted ? 'Answered' : 'Not Answered'}
                                                                </span>
                                                                {(!isMobile() && !el.isAttempted) && (
                                                                    <span
                                                                        onClick={() => {
                                                                            closeOverlay('visibleSubmitOverlay')
                                                                            history.push({
                                                                                pathname: `/homework/${courseId}/${topicId}/quiz`,
                                                                                state: {
                                                                                    activeQuestionIndex: index,
                                                                                }
                                                                            })
                                                                        }}
                                                                        className={styles.editIcon}
                                                                    />
                                                                )}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : null}
                                        </>
                                    )
                                }
                                if (get(component, 'componentName') === 'homeworkAssignment') {
                                    return (
                                        <>
                                            {(assignmentQuestions && assignmentQuestions.length) ? (
                                            <>
                                                <span className={styles.summaryTitle} style={{ marginTop: 18}}>
                                                    Section {index+1} : Coding Assignment
                                                </span>
                                                <span className={styles.questionContainer} style={{padding: '6px 4px'}}>
                                                    <span className={styles.headerText}>Name of the questions</span>
                                                    <span className={styles.headerText}>Status</span>
                                                </span>
                                                {assignmentQuestions.map((el,index) => (
                                                    <div className={styles.questionContainer}>
                                                        {
                                                            isBase64(el.assignmentQuestion.statement) ? 
                                                            (
                                                                <span className={styles.questionText}>
                                                                   <pre>{index+1}.</pre>
                                                                   <Preview value={el.assignmentQuestion.statement} useNativeHtmlParser={true}/>
                                                                </span>
                                                            )
                                                            :
                                                            (<span className={styles.questionText}>{index+1}. <p>{el.assignmentQuestion.statement}
                                                                </p></span>)
                                                        }
                                                        {/* <span className={styles.questionText}>{index+1}. {el.assignmentQuestion.statement}</span> */}
                                                        <span className={styles.tagContainer}>
                                                            <span className={cx({
                                                                [styles.answeredTag]: true,
                                                                [styles.unAnsweredTag]: !el.isAttempted,
                                                            })}>
                                                                {el.isAttempted ? 'Answered' : 'Not Answered'}
                                                            </span>
                                                            {(!isMobile() && !el.isAttempted) && (
                                                                <span
                                                                    onClick={() => {
                                                                        closeOverlay('visibleSubmitOverlay')
                                                                        history.push({
                                                                            pathname: `/homework/${courseId}/${topicId}/codingAssignment`,
                                                                            state: {
                                                                                activeQuestionIndex: index,
                                                                            }
                                                                        })
                                                                    }}
                                                                    className={styles.editIcon}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                ))}
                                            </>
                                        ) : null}
                                        </>
                                    )
                                }
                                if (get(component, 'componentName') === 'homeworkPractice') {
                                    return (
                                        <>
                                            {(practiceQuestions && practiceQuestions.length) ? (
                                                <>
                                                    <span className={styles.summaryTitle} style={{ marginTop: 18}}>
                                                        Section {index+1} : Practice
                                                    </span>
                                                    <span className={styles.questionContainer} style={{padding: '6px 4px'}}>
                                                        <span className={styles.headerText}>Name of the questions</span>
                                                        <span className={styles.headerText}>Status</span>
                                                    </span>
                                                    {practiceQuestions.map((el,index) => (
                                                        <div className={styles.questionContainer}>
                                                            <span className={styles.questionText}>{index+1}. {get(el, 'blockBasedPractice.title', '')}</span>
                                                            <span className={styles.tagContainer}>
                                                                <span className={cx({
                                                                    [styles.answeredTag]: true,
                                                                    [styles.unAnsweredTag]: checkIfPracticeUnAnswered(el),
                                                                })}>
                                                                    {(get(el, 'blockBasedPractice.isSubmitAnswer') ? (get(el, 'answerLink', null) || get(el, 'savedBlocks') || get(el, 'attachments', []).length) : true) ? 'Answered' : 'Not Answered'}
                                                                </span>
                                                                {!isMobile() && (get(el, 'blockBasedPractice.isSubmitAnswer') ? !el.answerLink : false && (
                                                                    <span
                                                                        onClick={() => {
                                                                            closeOverlay('visibleSubmitOverlay')
                                                                            history.push(`/homework/${courseId}/${topicId}/${get(el, 'blockBasedPractice.id')}/practice`)
                                                                        }}
                                                                        className={styles.editIcon}
                                                                    />
                                                                ))}
                                                            </span>
                                                        </div>
                                                    ))}
                                                </>
                                            ) : null}
                                        </>
                                    )
                                }
                            })}
                        </div>
                    ) : (
                        <ContentLoader
                            className={styles.qrLoaderCard}
                            speed={5}
                            interval={0.1}
                            backgroundColor={'#f5f5f5'}
                            foregroundColor={'#dbdbdb'}
                        >
                            {isMobile() ? (
                                <>
                                    <rect className={styles.qrLoader1Mobile} />
                                </>
                            ) : (
                                <>
                                    <rect className={styles.qrLoader1} />
                                    <rect className={styles.qrLoader2} />
                                    <rect className={styles.qrLoader3} />
                                </>
                            )}
                        </ContentLoader>
                    )}
                </>
            ) : (
                <>
                    <div className={styles.modalTitle}>{title}</div>
                    <div className={styles.modalIconContainer}> <span className={styles.saveIcon} /> </div>
                    <div className={styles.confirmText}>
                        {message || ''}
                        <span> {description || ''} </span>
                    </div>
                </>
            )}
            <div className={styles.buttonArea}>
                <div onClick={() => closeOverlay('visibleSubmitOverlay')} className={styles.cancelBtn}>
                    Go Back
                </div>
                <div
                    onClick={() => {
                        if (disabled) {
                            return
                        }
                       if (submitForReviewOverlay) {
                            if (isMobile()) {
                                setSubmitForReviewClicked(true)
                            }
                            onSubmitForReview()
                        } else {
                            onQuizSubmit()
                        }
                    }}
                    className={
                        cx(
                            styles.submitBtn,
                            disabled ? styles.disabled : ''
                        )
                    }
                >
                    <div
                        style={{
                            marginRight: `${isLoading ? hs(10) : hs(0)}px`
                        }}
                    >
                        { getButtonTitle(path) }
                    </div>
                    <div
                        style={{
                            marginLeft: `${isLoading ? hs(10) : hs(0)}px`
                        }}
                    >
                        <SimpleButtonLoader
                            showLoader={isLoading}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default SubmitOverlay
