import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route } from './components/RouterWithNav'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import qs from 'query-string'
import { get } from 'lodash'
import { filterKey } from './utils/data-utils'

const PrivateRoute = ({ component: Component, hasLogin, ...rest }) => {
  React.useEffect(() => {
    if (rest.path !== "/sessions" && rest.path !== "/homework" && rest.name !== "School Students" && rest.name !== 'Student Profile' && rest.name !== 'School Mentor' &&
      rest.name !== 'Mentor Profile' && rest.name !== 'School Grades' && rest.name !== 'School Dashboard' && rest.name !== 'homework') {
      if (window && window.fcWidget) {
        window.fcWidget.hide()
      }
    }
  })
  return (
    <Route
      {...rest}
      render={props => {
        if (rest.user.get('id') && rest.user.get('name')) {
          return <Component {...props} key={rest.keyPath ? get(rest, `computedMatch.params.${rest.keyPath}`, 'root') : 'root'} />
        }

        if (rest.hasMultipleChildrenButUserNotSelected) {
          return <Redirect
            to={{
              pathname: '/switch-account',
              state: { from: props.location }
            }}
          />
        }

        const params = qs.parse(window.location.search)
        if (!!Object.keys(params).length && params.redirect) {
          return (
            <Redirect
              to={{
                pathname: params.redirect,
                state: { from: props.location },
                search: qs.stringify({ ...params, redirect: rest.path })
              }}
            />
          )
        }
        if (!!Object.keys(params).length) {
          return (
            <Redirect
              to={{
                pathname: '/',
                state: { from: props.location },
                search: qs.stringify({ redirect: rest.path, ...params })
              }}
            />
          )
        }

        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )

      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({}).isRequired,
  user: PropTypes.shape({}).isRequired,

}

export default connect(state => ({
  hasMultipleChildrenButUserNotSelected:
    state.data.getIn(['userChildren', 'data']).size,
  user: (filterKey(state.data.getIn(['user', 'data']), 'loggedinUser').get(0) || Map({}))
}))(PrivateRoute)
