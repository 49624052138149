import React, { Component, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import "./NavBar.scss";
import profileDropdownBG from "../../assets/profileDropdownBG.jpg";
import profileDropdownBG2X from "../../assets/profileDropdownBG@2x.jpg";
import profileDropdownBG3X from "../../assets/profileDropdownBG@3x.jpg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { ReactComponent as LogoutIcon } from "../../assets/logout.svg";
import CreditIcon from "../../components/Buttons/CreditButton/CreditIcon";
import cx from "classnames";
import { Link, withRouter } from "react-router-dom";
import { ReactComponent as Drop } from "./drop.svg";
import { hs, hsm } from "../../utils/size";
import isFeatureEnabled from "../../utils/isFeatureEnabled";

const transition = {
  type: "tween",
  ease: "anticipate",
  duration: 0.3
};

const getWidth = width => {
  if (width < 500) {
    return { rest: hsm(40), move: hsm(15) };
  } else if (width >= 500 && width < 600) {
    return { rest: hsm(39), move: hsm(15) };
  } else if (width >= 600 && width < 700) {
    return { rest: hsm(38), move: hsm(15) };
  } else if (width >= 700 && width < 800) {
    return { rest: hsm(30), move: hsm(15) };
  } else if (width >= 800 && width < 900) {
    return { rest: hsm(28), move: hsm(15) };
  } else if (width >= 900 && width <= 1000) {
    return { rest: hsm(26), move: hsm(15) };
  }

  return { rest: hs(56), move: hs(30) };
};

let cancelClosing = null;
let cancelClosingDropdown = null;
const ProfileIcon = props => {
  const [creditHovered, setCreditHovered] = useState(false);
  const isCreditPage = props.location.pathname === "/settings/myReferrals";

  const variants = {
    drop: {
      rest: {
        scale: 1
      },
      hover: {
        scale: 1
      }
    },
    container: {
      rest: {
        width: getWidth(props.width).rest,
        transition
      },
      hover: {
        width: "60%",
        transition
      }
    },
    creditsContainer: {
      rest: {
        width: `calc(100% - ${getWidth(props.width).rest}px)`,
        transition
      },
      hover: {
        width: `calc(100% - ${getWidth(props.width).move}px)`,
        transition
      }
    }
  };

  const referralFeature = isFeatureEnabled('referralFeature')

  return (
    <motion.div
      className={cx('main_navbar_profileIconContainer', !referralFeature && 'main_navbar_profileIconContainerNoReferral')}
      id="header__icon__profile"
      onMouseEnter={e => {}}
      style={{
        position: "relative",
      }}
    >
    {referralFeature && (
      <motion.div
        className={'main_navbar_creditsContainer'}
        onClick={() => {
          if (props.width >= 1000) {
            props.history.push("/settings/myReferrals");
          }
        }}
        initial="rest"
        animate="rest"
        whileHover="hover"
      >
        <motion.div
          className={'main_navbar_coin'}
          variants={variants}
          style={{
            position: "relative",
            zIndex: creditHovered ? 99 : 2,
            pointerEvents: "none"
          }}
        >
          <CreditIcon />
        </motion.div>
        <div
          className={'main_navbar_creditsText'}
          style={{
            position: "relative",
            zIndex: creditHovered ? 99 : 2,
            pointerEvents: "none"
          }}
        >
          {props.credits}
        </div>
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: hs(80),
            height: "100%",
            borderRadius: `0 ${props.width > 1000 ? hs(26) : hsm(26)}px ${
              props.width > 1000 ? hs(26) : hsm(26)
            }px 0`,
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 7,
            pointerEvents: !creditHovered ? "none" : "auto"
          }}
          onMouseEnter={() => {
            props.openDropdown();
            setCreditHovered(false);
          }}
          initial={{ opacity: 0 }}
          animate={
            creditHovered
              ? { opacity: 1, ...transition }
              : { opacity: 0, ...transition }
          }
        ></motion.div>
        <motion.div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: `${props.width > 1000 ? hs(26) : hsm(26)}px 0 0 ${
              props.width > 1000 ? hs(26) : hsm(26)
            }px`,
            backgroundImage: "linear-gradient(to bottom, #00ade6, #34e4ea)",
            zIndex: creditHovered ? 7 : 1
          }}
          onMouseEnter={() => {
            setCreditHovered(true);
          }}
          onMouseLeave={() => {
            setCreditHovered(false);
          }}
          initial={variants.creditsContainer.rest}
          animate={
            creditHovered
              ? variants.creditsContainer.hover
              : variants.creditsContainer.rest
          }
        ></motion.div>
        </motion.div>
      )}
      <div
        onMouseEnter={() => {
          clearTimeout(cancelClosing)
          clearTimeout(cancelClosingDropdown)
          props.openDropdown()
        }}
        onMouseLeave={() => {
          cancelClosing = setTimeout(props.closeDropdown, 500);
        }}
      >
        <motion.div
          className={cx('main_navbar_profileIcon', !referralFeature && 'main_navbar_profileIconNoReferral')}
          initial="rest"
          animate="rest"
          whileHover="hover"
        >
          {referralFeature && (
            <>
              <motion.div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "40%",
                  height: "100%",
                  borderRadius: `${props.width > 1000 ? hs(26) : hsm(26)}px 0 0 ${
                    props.width > 1000 ? hs(26) : hsm(26)
                  }px`,
                  backgroundColor: "rgba(0, 0, 0, 0.6)",
                  zIndex: 3,
                  pointerEvents: !props.isDropDownVisible ? "none" : "auto"
                }}
                onMouseEnter={props.closeDropdown}
                initial={{ opacity: 0 }}
                animate={
                  props.isDropDownVisible
                    ? { opacity: 1, ...transition }
                    : { opacity: 0, ...transition }
                }
              ></motion.div>
              <motion.div
                style={{
                  position: "absolute",
                  top: `${400 <= props.width <= 600 ? "-1px" : "0"}`,
                  right: 0,
                  height: `${400 <= props.width <= 600 ? "103%" : "100%"}`,
                  borderRadius: `0 ${props.width > 1000 ? hs(26) : hsm(26)}px ${
                    props.width > 1000 ? hs(26) : hsm(26)
                  }px 0`,
                  backgroundColor: "#e0fcfd",
                  zIndex: 3,
                  width: `${hsm(43)}px`
                }}
                initial={variants.container.rest}
                animate={
                  props.isDropDownVisible
                    ? variants.container.hover
                    : variants.container.rest
                }
              ></motion.div>
            </>
          )}
          <motion.div
            style={{
              position: "relative",
              zIndex: 5,
              pointerEvents: 'none'
            }}
            className={cx('main_navbar_profileWrapper', !referralFeature && 'main_navbar_profileWrapperNoReferral')}
            animate={{
              x: props.isDropDownVisible ? hs(referralFeature ? -25 : 0) : 0
            }}
          >
            <Drop />
          </motion.div>
        </motion.div>
      </div>
      <motion.div
        onMouseEnter={() => {
          clearTimeout(cancelClosing)
          clearTimeout(cancelClosingDropdown)
        }}
        onMouseLeave={() => {
          cancelClosingDropdown = setTimeout(() => {
            props.closeDropdown();
          }, 500);
        }}
        initial={{
          opacity: 0
        }}
        animate={
          props.isDropDownVisible
            ? {
                opacity: 1
              }
            : { opacity: 0 }
        }
        style={props.isDropDownVisible ? { pointerEvents: "auto" } : { pointerEvents: "none" }}
      >
        <div className={cx('main_navbar_arrowUp', !referralFeature && 'main_navbar_arrowUpNoReferral')}></div>
        <div className={'main_navbar_dropDownContainer'}>
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: -2,
              // backgroundColor: "white"
            }}
          ></div>
          <div className={'main_navbar_dropDownItemsLayout'}>
            {props.hasMultipleChildren && (
              <>
                <Link
                  to="/switch-account"
                  className={cx('main_navbar_dropDownRow', 'main_navbar_link')}
                >
                  <span className={'main_navbar_rowText'}>Switch Account</span>
                </Link>
                <div className={'main_navbar_horizontalLine'}></div>
              </>
            )}
            {props.width > 1000 ? (
              <>
                <Link
                  to="/settings/account"
                  className={cx('main_navbar_dropDownRow', 'main_navbar_link')}
                >
                  <div className={'main_navbar_settingsIcon'}>
                    <SettingsIcon />
                  </div>
                  <span className={'main_navbar_rowText'}>Settings</span>
                </Link>
                <Link
                  to={'/student-community'}
                  className={cx('main_navbar_dropDownRow', 'main_navbar_link')}
                >
                  <span className={'main_navbar_studentCommunityIcon'} />
                  <span className={'main_navbar_rowText'}>Student Community</span>
                </Link>
                <div className={'main_navbar_horizontalLine'}></div>
                <Link
                  to="/my-code"
                  className={cx('main_navbar_dropDownRow', 'main_navbar_link')}
                >
                  <span className={'main_navbar_savedCodeIcon'} />
                  <span className={'main_navbar_rowText'}>My Code</span>
                </Link>
                <div className={'main_navbar_horizontalLine'}></div>
                <Link
                  to="/selected-code/stats"
                  className={cx('main_navbar_dropDownRow', 'main_navbar_link')}
                >
                  <span className={'main_navbar_savedCodeIcon'} />
                  <span className={'main_navbar_rowText'}>Published Code</span>
                </Link>
                <div className={'main_navbar_horizontalLine'}></div>
              </>
            ) : (
              <div>
                <Link
                  to="/my-code"
                  className={cx('main_navbar_dropDownRow', 'main_navbar_link')}
                >
                  <span className={'main_navbar_savedCodeIcon'} />
                  <span className={'main_navbar_rowText'} style={{ textAlign: 'left' }}>My Code</span>
                </Link>
              </div>
            )}
            <div
              className={'main_navbar_dropDownRow'}
              onClick={() => {
                props.logout();
              }}
            >
              <div className={'main_navbar_logoutIcon'}>
                <LogoutIcon />
              </div>
              <span className={'main_navbar_rowText'}>Logout</span>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default withRouter(ProfileIcon);
