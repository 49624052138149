const TOPIC_COMPONENTS = {
  video: 'video',
  learningObjective: 'learningObjective',
  assignment: 'assignment',
  homeworkAssignment: 'homeworkAssignment',
  quiz: 'quiz',
  blockBasedProject: 'blockBasedProject',
  blockBasedPractice: 'blockBasedPractice',
  homeworkPractice: 'homeworkPractice',
};

const { homeworkAssignment, homeworkPractice, quiz } = TOPIC_COMPONENTS;
const HOMEWORK_COMPONENTS = [homeworkAssignment, homeworkPractice, quiz]

export {
    TOPIC_COMPONENTS,
    HOMEWORK_COMPONENTS,
}