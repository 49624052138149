const mixPanelRoutesToElementMap = {
    sessions: {
        pageIdentifier: 'sessions-page-mixpanel-identifier',
        pageTitle: 'Sessions'
    },
    video: {
        pageIdentifier: 'video-page-mixpanel-identifier',
        pageTitle: 'Video'
    },
    chat: {
        pageIdentifier: 'chat-page-mixpanel-identifier',
        pageTitle: 'Chat'
    },
    'comic-strip': {
        pageIdentifier: 'comic-strip-page-mixpanel-identifier',
        pageTitle: 'Comic Strip'
    },
    'learning-slides': {
        pageIdentifier: 'learning-slides-page-mixpanel-identifier',
        pageTitle: 'Learning Slides'
    },
    'practice-quiz': {
        pageIdentifier: 'practice-quiz-page-mixpanel-identifier',
        pageTitle: 'Practice Quiz'
    },
    'practice-report': {
        pageIdentifier: 'practice-report-page-mixpanel-identifier',
        pageTitle: 'Practice Report'
    },
    project: {
        pageIdentifier: 'project-page-mixpanel-identifier',
        pageTitle: 'Project'
    },
    practice: {
        pageIdentifier: 'practice-page-mixpanel-identifier',
        pageTitle: 'Practice'
    },
    codingAssignment: {
        pageIdentifier: 'codingAssignment-page-mixpanel-identifier',
        pageTitle: 'Coding Assignment'
    },
    coding: {
        pageIdentifier: 'codingAssignment-page-mixpanel-identifier',
        pageTitle: 'Coding Assignment'
    },
    homework: {
        pageIdentifier: 'homework-page-mixpanel-identifier',
        pageTitle: 'Homework'
    },
    quiz: {
        pageIdentifier: 'quiz-page-mixpanel-identifier',
        pageTitle: 'Quiz'
    },
    'homework-quiz': {
        pageIdentifier: 'quiz-page-mixpanel-identifier',
        pageTitle: 'Quiz'
    },
    'quiz-report': {
        pageIdentifier: 'quiz-report-page-mixpanel-identifier',
        pageTitle: 'Quiz Report'
    },
    'quiz-report-first': {
        pageIdentifier: 'quiz-report-page-mixpanel-identifier',
        pageTitle: 'First Quiz Report'
    },
    'quiz-report-latest': {
        pageIdentifier: 'quiz-report-page-mixpanel-identifier',
        pageTitle: 'Latest Quiz Report'
    },
    'homework-practice': {
        pageIdentifier: 'practice-page-mixpanel-identifier',
        pageTitle: 'Homework Practice'
    },
    'homework-codingAssignment': {
        pageIdentifier: 'codingAssignment-page-mixpanel-identifier',
        pageTitle: 'Homework Assignment'
    },
    'code-playground': {
        pageIdentifier: 'code-playgroud-page-mixpanel-identifier',
        pageTitle: 'Code Playground'
    },
    'student-community': {
        pageIdentifier: 'student-community-page-mixpanel-identifier',
        pageTitle: 'Student Community'
    },
    cheatsheet: {
        pageIdentifier: 'cheatsheet-page-mixpanel-identifier',
        pageTitle: 'Cheatsheet'
    }
}

export const mixPanelEvents = {
    PAGE_VIEWED: 'page_viewed'
}

export const appNames = {
    STUDENT_APP: 'Student App',
    TEACHER_APP: 'Teacher App',
    DESKTOP_APP: 'Desktop App'
}

export default mixPanelRoutesToElementMap;
