import React from "react"

export const VideocamIcon = () => (
    <svg width="30" height="30" viewBox="0 0 24 25" fill="inherit" stroke="inherit" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.5702 15.1657L21.4473 17.8948C21.5615 17.9455 21.6865 17.967 21.8111 17.9571C21.9357 17.9472 22.0558 17.9064 22.1606 17.8383C22.2654 17.7702 22.3515 17.6771 22.4111 17.5672C22.4707 17.4574 22.502 17.3344 22.502 17.2094V8.17382C22.502 8.04885 22.4707 7.92587 22.4111 7.81604C22.3515 7.70621 22.2654 7.61302 22.1606 7.54493C22.0558 7.47684 21.9357 7.43602 21.8111 7.42616C21.6865 7.41631 21.5615 7.43774 21.4473 7.48851L17.5702 10.2176C17.4719 10.2868 17.3917 10.3786 17.3363 10.4853C17.2809 10.592 17.252 10.7105 17.252 10.8307V14.5526C17.252 14.6728 17.2809 14.7913 17.3363 14.898C17.3917 15.0047 17.4719 15.0965 17.5702 15.1657V15.1657Z" stroke="inherit" stroke-width="2" fill="inherit" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.5625 18.6934H3.9375C3.29161 18.6915 2.6727 18.4341 2.21598 17.9774C1.75926 17.5207 1.50186 16.9018 1.5 16.2559V9.13086C1.50186 8.48496 1.75926 7.86605 2.21598 7.40934C2.6727 6.95262 3.29161 6.69521 3.9375 6.69336H12.585C13.2249 6.69534 13.838 6.95041 14.2905 7.40288C14.743 7.85535 14.998 8.46847 15 9.10836V16.2559C14.9981 16.9018 14.7407 17.5207 14.284 17.9774C13.8273 18.4341 13.2084 18.6915 12.5625 18.6934Z" stroke="inherit" stroke-width="2" fill="inherit" stroke-miterlimit="10"/>
    </svg>
)

export const Dropdown = () => (
    <svg width="23" height="24" viewBox="0 0 24 25" fill="inherit" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.8295 5.14982C8.26884 4.71048 8.98116 4.71048 9.4205 5.14982L16.1705 11.8998C16.6098 12.3392 16.6098 13.0515 16.1705 13.4908L9.4205 20.2408C8.98116 20.6801 8.26884 20.6801 7.8295 20.2408C7.39017 19.8015 7.39017 19.0892 7.8295 18.6498L13.784 12.6953L7.8295 6.74081C7.39017 6.30147 7.39017 5.58916 7.8295 5.14982Z" fill="inherit" stroke="inherit"/>
</svg>
)

export const DropDown2 = () => (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="23" height="24" viewBox="0 0 1333.000000 1280.000000"
 preserveAspectRatio="xMidYMid meet">
<g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)"
fill="inherit" stroke="inherit">
<path fill="inherit" stroke="inherit" d="M2791 8780 c-203 -60 -363 -228 -416 -435 -19 -76 -19 -214 0 -290 8
-33 32 -94 51 -135 36 -74 72 -110 1908 -1947 1793 -1793 1874 -1873 1944
-1907 106 -53 185 -69 317 -63 121 5 183 23 284 83 41 24 556 533 1922 1901
1752 1754 1867 1872 1896 1934 45 94 63 174 63 275 0 174 -52 305 -165 420
-79 80 -120 108 -220 150 -66 27 -78 29 -215 29 -137 0 -149 -2 -215 -29 -38
-16 -88 -40 -110 -54 -22 -13 -768 -751 -1658 -1640 l-1617 -1617 -1628 1626
c-1514 1513 -1632 1628 -1692 1657 -36 17 -93 38 -126 46 -85 22 -241 20 -323
-4z"/>
</g>
</svg>
)

export const DocIcon = () => (
    <svg width="30" height="30" viewBox="0 0 24 25" stroke="inherit" fill="inherit" xmlns="http://www.w3.org/2000/svg" className="docIcon">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.75 3.69531C6.35218 3.69531 5.97064 3.85335 5.68934 4.13465C5.40804 4.41596 5.25 4.79749 5.25 5.19531V20.1953C5.25 20.5931 5.40804 20.9747 5.68934 21.256C5.97064 21.5373 6.35218 21.6953 6.75 21.6953H17.25C17.6478 21.6953 18.0294 21.5373 18.3107 21.256C18.592 20.9747 18.75 20.5931 18.75 20.1953V11.0665C18.75 11.0665 18.75 11.0664 18.75 11.0664C18.7499 10.8676 18.671 10.677 18.5305 10.5364C18.5304 10.5364 18.5304 10.5364 18.5304 10.5364L11.9089 3.91492C11.7684 3.77436 11.5777 3.69537 11.3789 3.69531C11.3789 3.69531 11.3788 3.69531 11.3788 3.69531H6.75ZM4.62868 3.07399C5.19129 2.51138 5.95435 2.19531 6.75 2.19531H11.379C11.9756 2.1954 12.5476 2.43238 12.9695 2.85414L12.9695 2.8542L19.5912 9.47582C20.0129 9.89768 20.2499 10.4698 20.25 11.0663V20.1953C20.25 20.991 19.9339 21.754 19.3713 22.3166C18.8087 22.8792 18.0457 23.1953 17.25 23.1953H6.75C5.95435 23.1953 5.19129 22.8792 4.62868 22.3166C4.06607 21.754 3.75 20.991 3.75 20.1953V5.19531C3.75 4.39966 4.06607 3.6366 4.62868 3.07399Z" stroke="inherit" fill="inherit"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.57031C12.4142 2.57031 12.75 2.9061 12.75 3.32031V8.94531C12.75 9.14422 12.829 9.33499 12.9697 9.47564C13.1103 9.61629 13.3011 9.69531 13.5 9.69531H19.125C19.5392 9.69531 19.875 10.0311 19.875 10.4453C19.875 10.8595 19.5392 11.1953 19.125 11.1953H13.5C12.9033 11.1953 12.331 10.9583 11.909 10.5363C11.4871 10.1143 11.25 9.54205 11.25 8.94531V3.32031C11.25 2.9061 11.5858 2.57031 12 2.57031Z" stroke="inherit" fill="inherit"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 14.1953C7.5 13.7811 7.83579 13.4453 8.25 13.4453H15.75C16.1642 13.4453 16.5 13.7811 16.5 14.1953C16.5 14.6095 16.1642 14.9453 15.75 14.9453H8.25C7.83579 14.9453 7.5 14.6095 7.5 14.1953Z" stroke="inherit" fill="inherit"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.5 17.9453C7.5 17.5311 7.83579 17.1953 8.25 17.1953H15.75C16.1642 17.1953 16.5 17.5311 16.5 17.9453C16.5 18.3595 16.1642 18.6953 15.75 18.6953H8.25C7.83579 18.6953 7.5 18.3595 7.5 17.9453Z" stroke="inherit" fill="inherit"/>
</svg>
)

export const Chat = () => (
    <svg width="30" height="30" viewBox="0 0 48 48" stroke="inherit" fill="inherit" xmlns="http://www.w3.org/2000/svg">
<path d="M40.4099 30.0565C40.3161 29.719 40.5224 29.2502 40.7192 28.9127C40.7794 28.8123 40.8451 28.7153 40.9161 28.6221C42.6001 26.1198 43.5009 23.1727 43.5036 20.1565C43.5317 11.5127 36.238 4.50024 27.2192 4.50024C19.3536 4.50024 12.7911 9.85337 11.2536 16.9596C11.0236 18.0129 10.9073 19.0878 10.9067 20.1659C10.9067 28.819 17.9192 36.019 26.938 36.019C28.3724 36.019 30.3036 35.5877 31.363 35.2971C32.4224 35.0065 33.4724 34.6221 33.7442 34.519C34.0229 34.4139 34.3183 34.3599 34.6161 34.3596C34.9411 34.3584 35.263 34.4221 35.563 34.5471L40.8786 36.4315C40.9951 36.4808 41.1184 36.5124 41.2442 36.5252C41.4431 36.5252 41.6339 36.4462 41.7746 36.3056C41.9152 36.1649 41.9942 35.9742 41.9942 35.7752C41.9877 35.6894 41.972 35.6046 41.9474 35.5221L40.4099 30.0565Z" stroke="inherit" fill="inherit" stroke-width="2.24989" stroke-miterlimit="10" stroke-linecap="round"/>
<path d="M6.23605 21.751C5.00646 23.96 4.41164 26.4659 4.51737 28.9919C4.62309 31.5178 5.42523 33.9653 6.83511 36.0638C7.05168 36.391 7.17355 36.6441 7.13605 36.8138C7.09855 36.9835 6.01761 42.6141 6.01761 42.6141C5.99162 42.7459 6.00148 42.8822 6.04617 43.0089C6.09086 43.1355 6.16874 43.2478 6.27168 43.3341C6.40903 43.4435 6.57981 43.5025 6.75543 43.501C6.84927 43.5012 6.94215 43.4821 7.02824 43.4447L12.2979 41.3822C12.6606 41.2392 13.0652 41.246 13.4229 41.401C15.1985 42.0929 17.1617 42.526 19.1257 42.526C21.7613 42.5288 24.3506 41.8336 26.6304 40.5113" stroke="inherit" fill="inherit" stroke-width="2.24989" stroke-miterlimit="10" stroke-linecap="round"/>
</svg>
)

export const Practice = () =>(
    <svg width="30" height="30" viewBox="0 0 49 49" stroke="inherit" fill="inherit" xmlns="http://www.w3.org/2000/svg">
<path d="M24.2608 7.57642C20.962 7.57642 17.7374 8.55461 14.9946 10.3873C12.2518 12.22 10.114 14.8248 8.85163 17.8725C7.58926 20.9201 7.25896 24.2737 7.90252 27.509C8.54607 30.7444 10.1346 33.7163 12.4671 36.0488C14.7997 38.3814 17.7716 39.9699 21.0069 40.6134C24.2423 41.257 27.5958 40.9267 30.6435 39.6643C33.6911 38.4019 36.296 36.2642 38.1287 33.5214C39.9613 30.7786 40.9395 27.5539 40.9395 24.2552C40.9395 19.8317 39.1823 15.5894 36.0544 12.4615C32.9266 9.33364 28.6843 7.57642 24.2608 7.57642Z" stroke="inherit" fill="inherit" stroke-width="2.24989" stroke-miterlimit="10"/>
<path d="M18.9434 19.1676C18.9434 19.1676 19.023 17.5092 20.7979 16.0811C21.8508 15.2329 23.113 14.9875 24.2502 14.9704C25.286 14.9572 26.2109 15.1287 26.7644 15.3922C27.712 15.8432 29.5571 16.9444 29.5571 19.2861C29.5571 21.75 27.9461 22.8692 26.1105 24.1002C24.2749 25.3312 23.7764 26.6674 23.7764 28.0481" stroke="inherit" fill="inherit" stroke-width="1.96866" stroke-miterlimit="10" stroke-linecap="round"/>
<path class="pQpath" d="M25.5523 32.9747C25.5523 34.002 24.7195 34.8348 23.6922 34.8348C22.6649 34.8348 21.832 34.002 21.832 32.9747C21.832 31.9473 22.6649 31.1145 23.6922 31.1145C24.7195 31.1145 25.5523 31.9473 25.5523 32.9747Z" fill="inherit" stroke="inherit" stroke-width="0.0703092"/>
</svg>
)

export const Target = () => (
    <svg width="30" height="30" viewBox="0 0 24 25" fill="inherit" stroke="inherit" xmlns="http://www.w3.org/2000/svg">
<path d="M12 22.6953C17.5228 22.6953 22 18.2182 22 12.6953C22 7.17246 17.5228 2.69531 12 2.69531C6.47715 2.69531 2 7.17246 2 12.6953C2 18.2182 6.47715 22.6953 12 22.6953Z" fill="inherit" stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 18.6953C15.3137 18.6953 18 16.009 18 12.6953C18 9.3816 15.3137 6.69531 12 6.69531C8.68629 6.69531 6 9.3816 6 12.6953C6 16.009 8.68629 18.6953 12 18.6953Z" fill="inherit" stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 14.6953C13.1046 14.6953 14 13.7999 14 12.6953C14 11.5907 13.1046 10.6953 12 10.6953C10.8954 10.6953 10 11.5907 10 12.6953C10 13.7999 10.8954 14.6953 12 14.6953Z" fill="inherit" stroke="inherit" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
)

export const Brush = () => (
    <svg width="30" height="30" viewBox="0 0 24 25" fill="inherit" stroke="inherit" xmlns="http://www.w3.org/2000/svg" className="brushIcon">
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.9905 2.95912C18.4871 2.46256 19.1606 2.18359 19.8628 2.18359C20.5651 2.18359 21.2386 2.46256 21.7352 2.95912L21.2048 3.48953L21.7352 2.95914C22.2318 3.45573 22.5107 4.12923 22.5107 4.83149C22.5107 5.53131 22.2337 6.20257 21.7404 6.69865L11.4738 17.34C11.2749 17.5462 10.9759 17.6211 10.7033 17.5329C10.4307 17.4448 10.2322 17.209 10.1916 16.9254C10.1475 16.6166 9.94608 16.2357 9.60138 15.8932C9.25967 15.5536 8.84532 15.3205 8.47536 15.2452C8.21185 15.1915 7.99725 15.0009 7.91288 14.7455C7.82851 14.4902 7.8873 14.2092 8.06697 14.0092L17.9627 2.98838C17.9717 2.97838 17.981 2.96862 17.9905 2.95912ZM20.6745 4.0198C20.4593 3.80453 20.1673 3.68359 19.8628 3.68359C19.5643 3.68359 19.2777 3.7999 19.0637 4.00742L9.88945 14.2246C10.1714 14.3967 10.4314 14.6033 10.6587 14.8292C10.8529 15.0222 11.0337 15.2398 11.1883 15.4756L20.6651 5.65278C20.6674 5.65039 20.6698 5.648 20.6721 5.64563C20.6729 5.64482 20.6737 5.64401 20.6745 5.64321C20.8898 5.42792 21.0107 5.13594 21.0107 4.83149C21.0107 4.52706 20.8898 4.23509 20.6745 4.0198Z" fill="inherit" stroke="inherit"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.1875 19.0169C3.1875 17.1907 4.64282 15.6953 6.46875 15.6953C8.29468 15.6953 9.75 17.1907 9.75 19.0169C9.75 21.3142 7.91497 23.1953 5.625 23.1953C4.11012 23.1953 2.56344 22.4117 1.64778 21.1781C1.47886 20.9505 1.45265 20.6472 1.58002 20.394C1.70739 20.1408 1.9666 19.9811 2.25 19.9811C2.41596 19.9811 2.66556 19.9036 2.86894 19.7277C3.05473 19.567 3.1875 19.3386 3.1875 19.0169ZM6.46875 17.1953C5.49343 17.1953 4.6875 17.9968 4.6875 19.0169C4.6875 19.9565 4.21857 20.6299 3.62666 21.0328C4.21821 21.45 4.937 21.6953 5.625 21.6953C7.06441 21.6953 8.25 20.508 8.25 19.0169C8.25 17.9968 7.44407 17.1953 6.46875 17.1953Z" fill="inherit" stroke="inherit"/>
</svg>
)

export const HintIcon = (props) => (
    <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" className="hintIcon" {...props}>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 19.375C3.75 10.7464 10.7464 3.75 19.375 3.75C28.0036 3.75 35 10.7464 35 19.375C35 28.0036 28.0036 35 19.375 35C10.7464 35 3.75 28.0036 3.75 19.375ZM19.375 6.25C12.1271 6.25 6.25 12.1271 6.25 19.375C6.25 26.6229 12.1271 32.5 19.375 32.5C26.6229 32.5 32.5 26.6229 32.5 19.375C32.5 12.1271 26.6229 6.25 19.375 6.25Z" fill="inherit"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9375 17.1875C15.9375 16.4971 16.4971 15.9375 17.1875 15.9375L19.6875 15.9375C20.3779 15.9375 20.9375 16.4971 20.9375 17.1875L20.9375 26.25C20.9375 26.9404 20.3779 27.5 19.6875 27.5C18.9971 27.5 18.4375 26.9404 18.4375 26.25L18.4375 18.4375L17.1875 18.4375C16.4971 18.4375 15.9375 17.8779 15.9375 17.1875Z" fill="inherit"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M15 26.5625C15 25.8721 15.5596 25.3125 16.25 25.3125L23.125 25.3125C23.8154 25.3125 24.375 25.8721 24.375 26.5625C24.375 27.2529 23.8154 27.8125 23.125 27.8125L16.25 27.8125C15.5596 27.8125 15 27.2529 15 26.5625Z" fill="inherit"/>
        <path d="M18.6632 11.1222C18.8739 10.9814 19.1216 10.9063 19.375 10.9063C19.7148 10.9063 20.0407 11.0412 20.281 11.2815C20.5213 11.5218 20.6563 11.8477 20.6563 12.1875C20.6563 12.4409 20.5811 12.6886 20.4403 12.8993C20.2995 13.11 20.0994 13.2743 19.8653 13.3712C19.6312 13.4682 19.3736 13.4936 19.125 13.4441C18.8765 13.3947 18.6482 13.2727 18.469 13.0935C18.2898 12.9143 18.1678 12.686 18.1184 12.4375C18.0689 12.1889 18.0943 11.9313 18.1913 11.6972C18.2883 11.4631 18.4525 11.263 18.6632 11.1222Z" fill="inherit" stroke="#00ADE6" stroke-width="1.5"/>
    </svg>
)