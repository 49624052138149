import gql from "graphql-tag"
import moment from "moment"
import duck from "../../duck"

const startBatchSession = (sessionId, fromClassroomCoursePage, retakeSessionId) => {
    let startMinutes = 0
    if (fromClassroomCoursePage) startMinutes = moment().get('minutes')
    let batchSessionInput = ''
    if (retakeSessionId) batchSessionInput = `isRetakeSession: true`
    else batchSessionInput = `sessionStatus:started, ${fromClassroomCoursePage ? `startMinutes: ${startMinutes}` : ''}`
    return duck.query({
        query: gql`
           mutation{
               updateBatchSession(id:"${sessionId}",input:{${batchSessionInput}}){
                   id
                   isRetakeSession
               }
               ${retakeSessionId ? `updateRetakeSession(id:"${retakeSessionId}", input:{sessionStatus:started}){
                id
               }` : ''}
           }
        
        `,
        type: 'updateBatchSession/update',
        key: 'updateBatchSession'
    })
}

export default startBatchSession