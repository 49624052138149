import gql from 'graphql-tag'
import duck from '../../duck'
import getCourseId, { getCourseString } from '../../utils/getCourseId'

const fetchMenteeCourseSyllabus = (force = false, courseIdString = '') => {
    const courseId = getCourseId()
    return duck.createQuery({
          query : gql`
            mutation {
              menteeCourseSyllabus(${courseIdString || getCourseString()}){
                firstComponent {
                  componentName
                  childComponentName
                  componentId
                }
                course {
                  title
                  description
                }
                chaptersCount: totalChapters
                topicsCount: totalTopics
                skills {
                  name
                  image {
                    uri
                  }
                }
                projects {
                  title
                  projectThumbnail {
                    uri
                  }
                  tags {
                    title
                  }
                }
                previousTopic {
                  topicComponentRule {
                    componentName
                    order
                  }
                  topicId
                }
                upComingSession {
                  topicId
                  topicTitle
                  chapterId
                  chapterTitle
                  chapterOrder
                  topicThumbnail {
                    id
                    uri
                  }
                  topicThumbnailSmall {
                    id
                    uri
                  }
                  topicDescription
                  classType
                  topicOrder
                  slotTime
                  bookingDate
                  menteeSessionId
                  isAccessible
                }
                bookedSession{
                    topicId
                    topicTitle
                    chapterId
                    chapterTitle
                    chapterOrder
                    topicThumbnail {
                      id
                      uri
                    }
                    topicThumbnailSmall {
                      id
                      uri
                    }
                    topicDescription
                    classType
                    topicOrder
                    slotTime
                    menteeSessionId
                    bookingDate
                    isAccessible
                }
                completedSession{
                    topicId
                    topicTitle
                    chapterId
                    chapterTitle
                    chapterOrder
                    menteeSessionId
                    topicDescription
                    classType
                    isSubmittedForReview
                    topicThumbnailSmall {
                      id
                      uri
                    }
                    topicThumbnail {
                      id
                      uri
                    }
                    topicOrder
                    endingDate
                    mentorId
                    mentorName
                    mentorProfilePic {
                      uri
                    }
                }
                mentor{
                  id
                  name
                  description
                  averageRating
                  experienceYear
                  gitHubLink
                  linkedInLink
                  portfolioLink
                  sessionLink
                  googleMeetLink
                  profilePic{
                    id
                    uri
                    name
                  }
                }
                isPaid
              }
            }
          `,
          type: 'menteeCourseSyllabus/fetch',
          key: 'menteeCourseSyllabus',
          force,
          changeExtractedData: (extractedData, originalData) => {
            if (extractedData.menteeCourseSyllabus) {
              extractedData.menteeCourseSyllabus.courseId = courseId
            }
            return extractedData;
          }
  })
} 


export default fetchMenteeCourseSyllabus
