import duck from './index'
import { isImmutable } from 'immutable'
import store from '../store'

const isAlreadyInCache = ({
  key,
  type,
  force = false,
}) => {
  const queryName = type.split('/')[0]
  const status = store.getState().data.getIn([queryName, 'fetchStatus', key])
  if (status && !force) {
    if (isImmutable(status)) {
      const { loading, success } = status.toJS()
      if (loading || success) {
        return true
      }
    } else {
      const { loading, success } = status
      if (loading || success) {
        return true
      }
    }
  }
  return false
}

const queryMethod = async ({
  key,
  type,
  force = false,
  ...rest
}) => {
  if (isAlreadyInCache({
    key,
    type,
    force
  })) {
    return new Promise(() => {})
  }
  return {
    query: await duck.query({
      key,
      type,
      ...rest
    }),

  }
}
const createQueryMethod = ({
  key,
  type,
  force = false,
  ...rest
}) => {
  const query = duck.createQuery({
    key,
    type,
    ...rest     
  })
  return {
    mapStateToProps: query.mapStateToProps,
    call: () => {
      if (isAlreadyInCache({
        key,
        type,
        force
      })) {
        return Promise.resolve({});
      }
      return query.call()
    }
  }
}

const duckCacheAware = {
  query: queryMethod,
  createQuery: createQueryMethod,
}

export default duckCacheAware
