import React, { lazy } from 'react'
import Loadable from 'react-loadable'
import withSuspense, { DefaultLoader } from './components/Loading/withSuspense'
import Switch, { Route } from './components/RouterWithNav'
import PrivateRoute from './PrivateRoute'
import isFeatureEnabled from './utils/isFeatureEnabled'
import CodingAssignmentOld from './pages/CodingAssignment'
import CodingAssignmentNew from './pages/UpdatedCodingAssignment'
import isSchoolWebsite from './utils/isSchoolWebsite'
import extractSubdomain, { isSubDomainActive } from './utils/extractSubdomain'
import { checkIfDefaultCourse, getCodingLanguages } from './utils/getCourseId'
import { get } from 'lodash'
import { B2C_LOGIN_APP_PREFIX, STUDENT_APP_PREFIX } from './constants'


const LandingPage = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "landingPage" */`./pages/LandingPage/index.js`).then(object => object.default),
});
const RadioStreetLandingPage = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "radioStreet" */`./pages/LandingPage/RadioStreetCrackTheCode.js`).then(object => object.default),
});
const RadioStreetLandingPageOld = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "radioStreet" */`./pages/LandingPage/RadioStreet.js`).then(object => object.default),
});
const B2BLandingPage = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "b2bLandingPage" */`./pages/B2BLandingPage/index.js`).then(object => object.default),
});
const SubDomainLandingPage = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "subDomainLandingPage" */`./pages/SubDomainLandingPage/index.js`).then(object => object.default),
});
const CheatSheet = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "cheatSheet" */`./pages/CheatSheet/index.js`).then(object => object.default),
});
const Decrypter = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "decrypter" */`./pages/Decrypter/index.js`).then(object => object.default),
});
const EventDescPage = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "events" */`./pages/EventLandingPage/pages/EventDescription/index.js`)))
  : () => <div />
const Privacy = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "landingPage" */`./pages/LandingPage/components/PrivacyPolicy.js`).then(object => object.default),
});
const Terms = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "landingPage" */`./pages/LandingPage/components/Terms.js`).then(object => object.default),
});
const Refund = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "landingPage" */`./pages/LandingPage/components/Refund.js`).then(object => object.default),
});
const Signup = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "signUp" */`./pages/Signup/index.js`).then(object => object.default),
});
const SignupLogin = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "signUpLogin" */`./pages/Signup/SignupLogin.js`).then(object => object.default),
});
const SignupSchool = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "signUpLoginSchool" */`./pages/Signup/SignupSchool.js`).then(object => object.default),
});
const SignupRadioStreet = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "radioStreet" */`./pages/Signup/SignupRadioStreet.js`).then(object => object.default),
});
const CodeShowcase = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "studentCommunity" */`./pages/CodeShowcaseModule/CodeShowcase/index.js`).then(object => object.default),
});
const IndividualCodeShowcase = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "studentCommunity" */`./pages/CodeShowcaseModule/IndividualCodeShowcase/index.js`).then(object => object.default),
});
const CertificateShowcase = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "certificateShowcase" */`./pages/CertificateShowcase/index.js`).then(object => object.default),
});

const EventsLandingPage = Loadable({
  loading: () => <DefaultLoader />,
  loader: () => import(/* webpackChunkName: "events" */`./pages/EventLandingPage/pages/LandingPage/index.js`).then(object => object.default),
})
// const CoursePage = Loadable({
//   loading: () => <DefaultLoader />,
//   loader: () =>
//     import(
//       /* webpackChunkName: "[request]" */ `./pages/CoursePage/index.js`
//     ).then((object) => object.default),
// });
// const ClassDetail = typeof window !== 'undefined'
//   ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/Classroom/ClasroomDetail/index.js')))
//   : () => <div />

const EventCertificateShowcase = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "events" */'./pages/EventCertificateShowcase/index.js')))
  : () => <div />

const IqaReportShowcase = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "iQAReport" */'./pages/IqaReportShowcase/index.js')))
  : () => <div />

const TimeTable = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/TimeTable')))
  : () => <div />
const ClassroomHomePage = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/Classroom/Home')))
  : () => <div />
const Classrooms = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/Classrooms')))
  : () => <div />
// const Teacher = typeof window !== 'undefined'
// ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/Teacher')))
// : () => <div />
const HomeworkReview = typeof window !== 'undefined'
? withSuspense(lazy(() => import(/* webpackChunkName: "sessionEmbedable" */'./pages/TeacherApp/pages/HomeworkReview')))
: () => <div />
const PqReport = typeof window !== 'undefined'
? withSuspense(lazy(() => import('./pages/TeacherApp/pages/PqReport')))
: () => <div />
const PqReportTeacherTraining = typeof window !== 'undefined'
? withSuspense(lazy(() => import('./pages/PQReportTeacherTraining/index.js')))
: () => <div />
const Student = typeof window !== 'undefined'
? withSuspense(lazy(() => import('./pages/TeacherApp/pages/Student')))
: () => <div />
const Report = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import('./pages/TeacherApp/pages/Report')))
  : () => <div />
const FeedbackForm = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import('./pages/TeacherApp/pages/Report/FeedbackForm')))
  : () => <div />
// const Student = typeof window !== 'undefined'
// ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/Student')))
// : () => <div />
// const Report = typeof window !== 'undefined'
//   ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/Report')))
//   : () => <div />
// const FeedbackForm = typeof window !== 'undefined'
//   ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/pages/Report/FeedbackForm')))
//   : () => <div />
const PreCheckout = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "checkoutForm" */'./pages/PreCheckout')))
  : () => <div />
const SwitchAccount = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessions" */'./pages/SwitchAccount')))
  : () => <div />
// const Revisit = typeof window !== 'undefined'
//   ? withSuspense(lazy(() => import(/* webpackChunkName: "[request]" */'./pages/Revisit')))
//   : () => <div />
const HomePage = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "homepage" */'./pages/HomePage')))
  : () => <div />
const Editor = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "codeEditor" */'./pages/Editor/EditorPage.js')))
  : () => <div />
const Homework = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "homeworkPage" */'./pages/Homework')))
  : () => <div />
const Video = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsVideo" */'./pages/Video')))
  : () => <div />
const VideoDiscussion = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsVideo" */'./pages/VideoDiscussion')))
  : () => <div />
const Discussion = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsVideo" */'./pages/Discussion')))
  : () => <div />
const ChatBot = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsChat" */'./pages/ChatBot')))
  : () => <div />
const Chat = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsChat" */'./pages/Chat')))
  : () => <div />
const Practice = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsPQ" */'./pages/Practice')))
  : () => <div />
const PQReport = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsPQ" */'./pages/PQReport')))
  : () => <div />
const Quiz = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsQuiz" */'./pages/Quiz')))
  : () => <div />
const QuizReport = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsQuiz" */'./pages/QuizReport')))
  : () => <div />
const SeeAnswers = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsSeeAnswers" */'./pages/SeeAnswers')))
  : () => <div />
const AccountPage = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "settings" */'./pages/Account')))
  : () => <div />
const JourneyReport = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "settings" */'./pages/JourneyReport')))
  : () => <div />
const Achievements = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "settings" */'./pages/Achievements')))
  : () => <div />
const ComingSoon = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "settings" */'./pages/ComingSoon')))
  : () => <div />
const Invite = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "settings" */'./pages/Invite')))
  : () => <div />
const MyReferrals = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "settings" */'./pages/MyReferrals')))
  : () => <div />
const Checkout = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "checkout" */'./pages/Checkout')))
  : () => <div />
const CodePlayground = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "codePlayground" */'./pages/CodePlayground')))
  : () => <div />
const CodeShowcaseStats = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "studentCommunity" */'./pages/CodeShowcaseModule/Stats/')))
  : () => <div />
const SchoolDashboard = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboard" */'./pages/SchoolDashboard')))
  : () => <div />
const SchoolGrades = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboardGrades" */'./pages/SchoolDashboard/Grades')))
  : () => <div />
const SchoolStudents = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboardStudents" */'./pages/SchoolDashboard/Students')))
  : () => <div />
const SchoolStudentProfile = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboardStudents" */'./pages/SchoolDashboard/StudentProfile')))
  : () => <div />
const SchoolMentorProfile = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboardMentors" */'./pages/SchoolDashboard/MentorProfile')))
  : () => <div />
const SchoolProfile = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboard" */'./pages/SchoolDashboard/SchoolProfile')))
  : () => <div />
const SchoolMentors = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "schoolDashboardMentors" */'./pages/SchoolDashboard/Mentors')))
  : () => <div />
const UpdatedVideo = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedVideo" */'./pages/UpdatedSessions/Video/')))
  : () => <div />
const ComicStrip = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedComicStrip" */'./pages/UpdatedSessions/ComicStrip/')))
  : () => <div />
const UpdatedProject = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedProject" */'./pages/UpdatedSessions/Project/')))
  : () => <div />
const UpdatedPractice = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedPractice" */'./pages/UpdatedSessions/Practice/')))
  : () => <div />
const UpdatedPracticeQuiz = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedPQ" */'./pages/UpdatedSessions/PracticeQuiz')))
  : () => <div />
const UpdatedQuiz = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedQuiz" */`./pages/UpdatedSessions/Quiz`)))
  : () => <div />
const UpdatedQuizReport = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsUpdatedQuiz" */`./pages/UpdatedSessions/QuizReport`)))
  : () => <div />
const UpdatedLearningSlides = typeof window !== 'undefined'
? withSuspense(lazy(() => import(/* webpackChunkName: "sessionsLearningSlides" */`./pages/UpdatedSessions/LearningSlides`)))
: () => <div />
const Sessions = typeof window !== 'undefined'
? withSuspense(lazy(() => import(/* webpackChunkName: "sessions" */`./pages/Sessions`)))
: () => <div />
const DetailedReport = typeof window !== 'undefined'
? withSuspense(lazy(() => import(/* webpackChunkName: "sessions" */`./pages/TeacherApp/pages/DetailedReport`)))
: () => <div />
// const HomeworkPage = typeof window !== 'undefined'
//   ? withSuspense(lazy(() => import(/* webpackChunkName: "teacherApp" */'./pages/TeacherApp/Homework')))
//   : () => <div />
const GlobalOverlay = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "overlay" */`./components/GlobalOverlay`)))
  : () => <div />
const SchoolLiveClassLogin = typeof window !== 'undefined'
  ?  withSuspense(lazy(() => import(/* webpackChunkName: "schoolLiveLogin" */`./pages/Signup/schoolLiveClassLogin/SchoolLiveClassLogin`)))
  : () => <div />
const RedirectPage = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "redirectPage" */`./pages/EventLandingPage/pages/RedirectPage/RedirectPage`)))
  : () => <div />

const SessionEmbed = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "sessionEmbed" */`./pages/TeacherApp/pages/SessionEmbed`)))
  : () => <div />
const ClassroomDetails = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "Classrooms" */`./pages/TeacherApp/pages/Classroom/ClassroomDetails`)))
  : () => <div />

const StudentLevelReport = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "ClassroomReport" */`./pages/TeacherApp/pages/StudentLevelReport`)))
  : () => <div />

const IndividualStudentLevelReport = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "ClassroomReport" */`./pages/TeacherApp/pages/IndividualStudentReport`)))
  : () => <div />

const ClassroomCourseListing = typeof window !== 'undefined'
  ? withSuspense(lazy(() => import(/* webpackChunkName: "Classrooms" */`./pages/TeacherApp/pages/ClassroomCourseListing`)))
  : () => <div />

// const SessionEmbed = typeof window !== 'undefined'
//   ? withSuspense(lazy(() => import(/* webpackChunkName: "[request]" */'./pages/TeacherApp/pages/SessionEmbed')))
//   : () => <div />

const getLandingPage = () => {
  if (typeof window !== 'undefined') {
    if (isSchoolWebsite()) {
      return B2BLandingPage
    }
    if (extractSubdomain() === STUDENT_APP_PREFIX) {
      return SchoolLiveClassLogin
    }
    if (extractSubdomain() === B2C_LOGIN_APP_PREFIX) {
      return SignupLogin;
    }
    if (isSubDomainActive) {
      return SubDomainLandingPage
    }
  }
  return LandingPage
}

const getComponentBasedOnCourse = (LegacyComponent, UpdatedComponent = null) => {
  // return LegacyComponent
  if (!checkIfDefaultCourse() && UpdatedComponent) {
    return UpdatedComponent
  }
  return LegacyComponent
}

const getComponentBasedOnCodingLanguage = (components) => {
  // return LegacyComponent
  const language = get(getCodingLanguages(), '0.value', '').toLowerCase()
  if (components[language]) {
    return components[language]
  }
  return components.default
}

const getActiveTeacherAppRoute = (page) => {
  if (typeof window !== 'undefined') {
    switch (page) {
      case 'session-embed':
        const windowQueryParams = get(window, "location.search", "");
        if (windowQueryParams && windowQueryParams.includes('backToPage=Timetable')) return 'time-table';
        if (windowQueryParams && windowQueryParams.includes('backToPage=Report')) return 'reports';
        return 'classrooms'
      default:
        return null;
    }
  }
  return null;
};

// ⚠️ Note: All new routes should also be added to ./serverRoutes.js
//  else server side rendering will break
const routes = (props) => {
  const referralFeature = isFeatureEnabled('referralFeature')
  const CodingAssignment = getComponentBasedOnCourse(CodingAssignmentOld, CodingAssignmentNew)
  // const ChatPage = getComponentBasedOnCodingLanguage({
  //   javascript: ChatBot,
  //   default: Chat
  // })
  const ChatPage = getComponentBasedOnCourse(Chat, ChatBot)
  return (
    <>

      {
        (props.location && props.location.pathname && !props.location.pathname.startsWith('/sessions/'))
          ? (
            <GlobalOverlay {...props} />
          ) :
          (
            <div />
          )
      }
      <Switch>
        <Route exact path='/' component={getLandingPage()} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/spy-squad-camp' component={RadioStreetLandingPageOld} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/crack-the-code' component={RadioStreetLandingPage} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/spy-squad-camp-community' component={RadioStreetLandingPageOld} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/crack-the-code-community' component={RadioStreetLandingPage} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/events/spy-squad-camp-school' component={RadioStreetLandingPageOld} noNav noOverflow className='landing-page' fromSSR={props.fromSSR} />
        {/* <Route exact path='/login' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} /> */}
        <Route exact path='/trailer' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        {/* <Route exact path='/confirm-otp' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} /> */}
        <Route exact path='/intro-to-programming' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/if-else-statements' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/tekie-tour' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/tekie-story-naman-sonpar' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/snippets/:ytId' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} />
        <Route exact path='/switch-account' component={SwitchAccount} noNav />
        <Route exact path='/privacy' noNav component={Privacy} />
        <Route exact path='/terms' noNav component={Terms} />
        <Route exact path='/refund' noNav component={Refund} />
        <Route exact path='/signup-school' noNav component={SignupSchool} />
        <Route exact path='/login' noNav component={SignupLogin} />
        <Route exact path='/course' component={Sessions} hideNavLoggedOut />
        <Route exact path='/signup' noNav component={Signup} />
        <Route exact path='/registers' noNav component={LandingPage} />
        <Route exact path='/signup/spy-squad-camp' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/crack-the-code' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/crack-the-code-community' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/spy-squad-camp-community' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/crack-the-code-school' noNav component={SignupRadioStreet} />
        <Route exact path='/signup/spy-squad-camp-school' noNav component={SignupRadioStreet} />
        <Route exact path='/signin' noNav component={Signup} />
        {/* <Route exact path='/login' noNav component={SchoolLiveClassLogin} /> */}
        <Route exact path='/school/liveclass/login' noNav component={SchoolLiveClassLogin} />
        

        {/* <Route exact path='/login' component={LandingPage} noNav className='landing-page' fromSSR={props.fromSSR} /> */}
        <Route exact path='/pre-checkout' noNav component={PreCheckout} />
        <Route exact path='/student-community' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' />
        <Route exact path='/student-community/new' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' />
        <Route exact path='/student-community/best' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' />
        <Route exact path='/student-community/trending' component={CodeShowcase} className='code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut bodyContainerFull name='Student Community' />
        <Route exact path='/student-community/:id' component={IndividualCodeShowcase} className='ind-code-showcase' fromSSR={props.fromSSR} hideNavLoggedOut />
        <Route exact path='/course-completion/:code' component={CertificateShowcase} className='cert-showcase' fromSSR={props.fromSSR} noNav />
        <Route exact path='/' component={SubDomainLandingPage} className='subDomain-landing-page' fromSSR={props.fromSSR} noNav name='Sub Domain Landing Page' />
        <Route exact path='/event-certificate/:code' component={EventCertificateShowcase} className='cert-showcase' noNav />
        <Route exact path='/iqa-report/:code' component={IqaReportShowcase} className='cert-showcase' noNav />
        <PrivateRoute exact path='/selected-code/stats' component={CodeShowcaseStats} name='Published Code' />
        <PrivateRoute exact path='/sessions' component={Sessions} appSideNav navItem sessionsOnly name='sessions' showInHamMenu />
        {/* <PrivateRoute exact path='/my-courses' component={MyCourses} appSideNav showInHamMenu /> */}
        {/* <PrivateRoute exact path='/sessionsold' component={Sessions} appSideNav navItem sessionsOnly name='sessions' showInHamMenu /> */}
        <PrivateRoute exact path='/sessions/book' component={Sessions} appSideNav sessionsOnly name='sessions' />
        {/* <PrivateRoute exact path='/revisit' component={Revisit} navItem name='past sessions' sessionsOnly /> */}
        <PrivateRoute exact path='/learn' component={HomePage} navItem name='learn' hideInSession />
        <Route exact path='/code-playground' component={Editor} navItem name='code playground' hideNavLoggedOut />
        <PrivateRoute exact path='/code-playground/:id' component={Editor} name='code playground' parent='code playground' />
        <PrivateRoute exact path='/homework' component={Homework} sessionsOnly name='homework' background='#FAFAFA' />
        <PrivateRoute exact path='/video/:topicId' component={Video} sideNavItem parent='learn' />
        <PrivateRoute exact path='/video/:topicId/:learningObjectiveId' component={Video} sideNavItem parent='learn' />
        <PrivateRoute exact path='/sessions/video/:topicId/discussion' component={VideoDiscussion} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/:topicId/discussion' component={Discussion} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/chat/:topicId/:loId' component={ChatPage} sideNavItem parent='learn' keyPath="loId" />
        <PrivateRoute exact path='/practice/:topicId/:loId' component={Practice} sideNavItem parent='learn' />
        <PrivateRoute exact path='/practice-report/:topicId/:loId' component={PQReport} sideNavItem parent='learn' />
        <PrivateRoute exact path='/quiz/:topicId' component={Quiz} sideNavItem parent='learn' />
        <PrivateRoute exact path='/quiz-report-first/:topicId' component={QuizReport} param sideNavItem parent='learn' />
        <PrivateRoute exact path='/quiz-report-latest/:topicId' component={QuizReport} sideNavItem parent='learn' />
        <PrivateRoute exact path='/see-answers-first/:topicId' component={SeeAnswers} sideNavItem parent='learn' />
        <PrivateRoute exact path='/see-answers-latest/:topicId' component={SeeAnswers} sideNavItem parent='learn' />
        {/* Session Routes */}
        <PrivateRoute exact path='/sessions/video/:topicId' component={Video} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/video/:courseId/:topicId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav noMobileHeader />
        <PrivateRoute
          exact
          path='/quiz-report-first/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='homework'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/quiz-report-latest/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='homework'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/quiz-report-first/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/quiz-report-latest/:courseId/:topicId'
          component={getComponentBasedOnCourse(QuizReport, UpdatedQuizReport)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/chat/:topicId/:loId'
          parent='sessions'
          keyPath="loId"
          component={ChatPage}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
        />
        <PrivateRoute
          exact
          path='/sessions/chat/:courseId/:topicId/:loId'
          parent='sessions'
          keyPath="loId"
          component={ChatPage}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/revisit/sessions/chat/:courseId/:topicId/:loId'
          parent='sessions'
          keyPath="loId"
          component={ChatPage}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          revisitRoute
          noMobileHeader
        />
        <PrivateRoute exact path='/sessions/practice-report/:courseId/:topicId/:loId' component={PQReport} withUpdatedSideNav noMobileHeader />
        <PrivateRoute
          exact
          path='/sessions/practice-report/:topicId/:loId'
          component={PQReport}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute exact path='/sessions/practice/:topicId/:loId' component={Practice} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/quiz-report-latest/:topicId' component={QuizReport} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/sessions/see-answers-latest/:topicId' component={SeeAnswers} sideNavItem parent='sessions' />

        <PrivateRoute exact path='/sessions/comic-strip/:courseId/:topicId/:loId' component={ComicStrip} className='Comic Strips' withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/comic-strip/:courseId/:topicId/:loId' revisitRoute component={ComicStrip} className='Comic Strips' withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/sessions/project/:courseId/:topicId/:projectId' component={UpdatedProject} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/sessions/practice/:courseId/:topicId/:projectId' component={UpdatedPractice} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/homework/:courseId/:topicId/:projectId/practice' component={UpdatedPractice} withUpdatedSideNav parent='homework' />
        <PrivateRoute exact path='/sessions/practice-quiz/:courseId/:topicId/:loId' component={UpdatedPracticeQuiz} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/project/:courseId/:topicId/:projectId' revisitRoute component={UpdatedProject} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/practice/:courseId/:topicId/:projectId' revisitRoute component={UpdatedPractice} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/practice-quiz/:courseId/:topicId/:loId' revisitRoute component={UpdatedPracticeQuiz} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/practice-report/:courseId/:topicId/:loId' revisitRoute component={PQReport} withUpdatedSideNav noMobileHeader />

        <PrivateRoute exact path='/homework-assignment/:topicId/' component={CodingAssignment} sideNavItem parent='learn' />
        <PrivateRoute exact path='/homework/:topicId/codingAssignment' component={CodingAssignment} sideNavItem parent='homework' />
        <PrivateRoute exact path='/homework/:courseId/:topicId/codingAssignment' component={CodingAssignmentNew} withUpdatedSideNav parent='homework' />
        <PrivateRoute exact path='/homework/:topicId/quiz' component={Quiz} sideNavItem parent='homework' />
        <PrivateRoute
          exact
          path='/homework/:courseId/:topicId/quiz'
          component={getComponentBasedOnCourse(Quiz, UpdatedQuiz)}
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          parent='homework'
        />
        <PrivateRoute
          exact
          path='/sessions/:courseId/:topicId/quiz'
          component={UpdatedQuiz}
          withUpdatedSideNav
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/:courseId/:topicId/codingAssignment'
          component={CodingAssignmentNew}
          withUpdatedSideNav
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/:courseId/:topicId/:projectId/practice'
          component={UpdatedPractice}
          withUpdatedSideNav
          parent='sessions'
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/sessions/coding/:topicId'
          component={CodingAssignment}
          parent='sessions'
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
        />
        <PrivateRoute
          exact
          path='/revisit/sessions/coding/:topicId'
          component={CodingAssignment}
          parent='sessions'
          revisitRoute
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
        />
        <PrivateRoute
          exact
          path='/sessions/coding/:courseId/:topicId'
          component={CodingAssignmentNew}
          parent='sessions'
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          noMobileHeader
        />
        <PrivateRoute
          exact
          path='/revisit/sessions/coding/:courseId/:topicId'
          component={CodingAssignmentNew}
          parent='sessions'
          revisitRoute
          sideNavItem={checkIfDefaultCourse()}
          withUpdatedSideNav={!checkIfDefaultCourse()}
          noMobileHeader
        />
        <PrivateRoute exact path='/sessions/learning-slides/:courseId/:topicId/:loId' component={UpdatedLearningSlides} name='Learning Slides' withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/learning-slides/:courseId/:topicId/:loId' component={UpdatedLearningSlides} name='Learning Slides' withUpdatedSideNav noMobileHeader revisitRoute />
        <PrivateRoute exact path='/sessions/codingAssignment/:topicId' component={CodingAssignment} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/codingAssignment/:topicId' component={CodingAssignment} sideNavItem parent='learn' />
        <PrivateRoute exact path='/revisit/sessions/video/:topicId' component={Video} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/sessions/video/:courseId/:topicId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav revisitRoute noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/chat/:topicId/:loId' component={Chat} sideNavItem parent='sessions' revisitRoute keyPath="loId" />
        <PrivateRoute exact path='/revisit/sessions/practice/:topicId/:loId' component={Practice} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/sessions/practice-report/:topicId/:loId' component={PQReport} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/homework/:topicId/codingAssignment' component={CodingAssignment} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/homework/:courseId/:topicId/codingAssignment' component={CodingAssignment} sideNavItem parent='sessions' revisitRoute noMobileHeader />
        <PrivateRoute exact path='/revisit/homework/:topicId/quiz' component={Quiz} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/revisit/homework/:courseId/:topicId/quiz' component={Quiz} sideNavItem parent='sessions' revisitRoute />
        <PrivateRoute exact path='/settings/account' component={AccountPage} sideNavItem parent='settings' />
        <PrivateRoute exact path='/settings/journeyreport' component={JourneyReport} sideNavItem parent='settings' />
        <PrivateRoute exact path='/settings/achievements/:type' component={Achievements} sideNavItem parent='settings' />
        <PrivateRoute exact path='/settings/support' component={ComingSoon} sideNavItem parent='settings' />
        {referralFeature && <Route exact path='/settings/invite' component={Invite} sideNavItem parent='settings' showInHamMenu name="Gift & Earn" hideNavLoggedOut />}
        {referralFeature && <PrivateRoute exact path='/settings/myReferrals' component={MyReferrals} sideNavItem parent='settings' showInHamMenu name="My Credits" />}
        <PrivateRoute exact path='/sessions/video/:courseId/:topicId/:videoId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav parent='sessions' noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/video/:courseId/:topicId/:videoId' component={getComponentBasedOnCourse(Video, UpdatedVideo)} withUpdatedSideNav parent='sessions' revisitRoute noMobileHeader />
        <PrivateRoute exact path='/sessions/video/:topicId/:learningObjectiveId' component={Video} sideNavItem parent='sessions' />
        <PrivateRoute exact path='/checkout' noNav component={Checkout} />
        <PrivateRoute exact path='/codePlayground' component={CodePlayground} name='Saved Codes' />
        <Route exact path='/cheatsheet' navItem component={CheatSheet} name='Resources' hideNavLoggedOut />
        <Route exact path='/cheatsheet/:topicId' component={CheatSheet} name='CheatSheet' hideNavLoggedOut />
        <Route exact path='/cheatsheet/:topicId/:cheatsheetId' component={CheatSheet} name='CheatSheet' hideNavLoggedOut />
        <PrivateRoute exact path='/my-code' component={CodePlayground} name='My Code' />
        {/* School Dashboard Routes */}
        <PrivateRoute exact path='/dashboard/:schoolSlug/profile' noNav component={SchoolProfile} name='Mentor Profile' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/mentors/:mentorId' noNav component={SchoolMentorProfile} name='Mentor Profile' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/mentors' noNav component={SchoolMentors} name='School Mentor' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/students/:studentId' noNav component={SchoolStudentProfile} name='Student Profile' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/students' noNav component={SchoolStudents} name='School Students' />
        <PrivateRoute exact path='/dashboard/:schoolSlug/grades' noNav component={SchoolGrades} name='School Grades' />
        <PrivateRoute exact path='/dashboard/:schoolSlug' noNav component={SchoolDashboard} name='School Dashboard' />
        <Route exact path='/apps/binary-spy-tool' component={Decrypter} name='Decrypter' noNav />
        <Route exact path='/events' component={EventsLandingPage} name='EventLandingPage' hideNavLoggedOut showInHamMenu />
        <Route exact path='/events/:eventId' component={EventDescPage} name='EventDescPage' hideNavLoggedOut />
        <PrivateRoute exact path='/time-table' activeRoute='time-table' component={TimeTable} managementApp noNav name='Mentor Profile' />
        <PrivateRoute exact path='/reports' activeRoute='reports' component={ClassroomHomePage} managementApp noNav />
        <PrivateRoute exact path='/classrooms' activeRoute='classrooms' component={Classrooms} managementApp noNav />
        <PrivateRoute exact path='/reports/classroom/:batchId' activeRoute='reports' component={ClassroomDetails} managementApp noNav />
        {/* <PrivateRoute exact path='/classroom' activeRoute='classrooms' component={ClassDetail} managementApp noNav /> */}
        {/* <PrivateRoute exact path='/classroom/course' activeRoute='classrooms' component={HomeworkPage} managementApp noNav /> */}
        {/* <PrivateRoute exact path='/classroom/session' activeRoute='classrooms' component={Report} managementApp noNav /> */}
        {/* <PrivateRoute exact path='/classroom/feedback/:sessionId' activeRoute='classrooms' component={FeedbackForm} managementApp noNav /> */}
        <PrivateRoute exact path='/session-embed' activeRoute={getActiveTeacherAppRoute('session-embed')} component={SessionEmbed} managementApp noNav />
        <Route exact path='/redirect/:redirectId' component={RedirectPage} name='EventDescPage' hideNavLoggedOut />
        {/* <PrivateRoute exact path='/teachers' activeRoute='teachers' component={Teacher} managementApp noNav /> */}
        {/* <PrivateRoute exact path='/students' activeRoute='students' component={Student} managementApp noNav /> */}
        <PrivateRoute exact path='/homework-review' activeRoute='homework-review' component={HomeworkReview} managementApp noNav />
        <PrivateRoute exact path='/pq-report' activeRoute='pq-report' component={PqReport} managementApp noNav />
        <Route exact path='/teacher-report/:userId/:topicId' component={PqReportTeacherTraining} noNav noOverflow />
        <PrivateRoute exact path='/sessions/homework-review/:courseId/:topicId' activeRoute='homework-review' component={HomeworkReview} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/sessions/pq-report/:courseId/:topicId/:loId' activeRoute='homework-review' component={PqReport} withUpdatedSideNav noMobileHeader />
        <PrivateRoute exact path='/revisit/sessions/pq-report/:courseId/:topicId/:loId' activeRoute='homework-review' component={PqReport} withUpdatedSideNav noMobileHeader revisitRoute />
        <PrivateRoute exact path='/reports/classroom/:sessionId/student-level' activeRoute='reports' component={StudentLevelReport} managementApp noNav />
        <PrivateRoute exact path='/reports/classroom/:sessionId/question-level' activeRoute='reports' component={DetailedReport} managementApp noNav />
        <PrivateRoute exact path='/reports/classroom/:sessionId/student-level/:userId' activeRoute='reports' component={IndividualStudentLevelReport} managementApp noNav />
        <PrivateRoute exact path='/classrooms/:batchId' activeRoute='classrooms' component={ClassroomCourseListing} managementApp noNav />
        <Route path='/not-found' noNav component={() => <div>Not Found</div>} />
        <Route path='/' component={() => <div>Not Found</div>} />

      </Switch>
    </>
  )
}

export default routes