import get from "lodash/get";
import { buddyQueries } from "../../constants/buddyLogin";
import { filterKey, getBuddies } from "../../utils/data-utils";
import dumpBlockBasedPracticeForBuddies from "../buddyLogin/dumpBlockBasedPracticeForBuddies";
import dumpBlockBasedProjectForBuddies from "../buddyLogin/dumpBlockBasedProjectForBuddies";
import dumpChatForBuddies from "../buddyLogin/dumpChatForBuddies";
import dumpCodingAssignmentForBuddies from "../buddyLogin/dumpCodingAssignmentForBuddies";
import dumpComicStripForBuddies from "../buddyLogin/dumpComicStripForBuddies";
import dumpLearningSlideForBuddies from "../buddyLogin/dumpLearningSlideForBuddies";
import dumpPracticeQuestionForBuddies from "../buddyLogin/dumpPracticeQuestionForBuddies";
import dumpVideoForBuddies from "../buddyLogin/dumpVideoForBuddies";
import fetchBlockBasedPracticeForBuddies from "../buddyLogin/fetchBlockBasedPracticeForBuddies";
import fetchBlockBasedProjectsForBuddies from "../buddyLogin/fetchBlockBasedProjectsForBuddies";
import fetchChatPracticeForBuddies from "../buddyLogin/fetchChatPracticeForBuddies";
import fetchUserAssignmentForBuddies from "../buddyLogin/fetchUserAssignmentForBuddies";
import fetchUserVideosForBuddies from "../buddyLogin/fetchUserVideosForBuddies";

const buddyQueriesCaller = (typeOfQuery, args = { learningObjectiveId: '', courseId: '', topicId: '', pqDumpInput: {}, projectId: '', blockBasedPracticeId: '', blockBasedProjectId: '', learningSlideConnectId: '', courseConnectId: '', topicConnectId: '', learningObjectiveConnectId: '', blockBasedProjectInput: {}, codingAssignmentInput: {}, blockBasedPracticeInput: {}, comicStripInput: {}, learningSlideInput: {}, inputvideoAction: { videoAction: 'skip' }, chatAction: { chatAction: "next" }, tokenType: '' }) => {

    const user = filterKey(
        window && window.store.getState().data.getIn(["user", "data"]),
        "loggedinUser"
    ).get(0) || new Map({});
    const userBuddies = getBuddies(get(user.toJS(), 'buddyDetails', []))

    if (!userBuddies.length) return
    const buddyQueriesTracker = JSON.parse(localStorage.getItem('buddyQueriesTracker'))
    switch (typeOfQuery) {
        case buddyQueries.USER_LEARNING_OBJECTIVES: {
            const { learningObjectiveId, tokenType, courseId, topicId } = args
            return fetchChatPracticeForBuddies({ userBuddies, learningObjectiveId, tokenType, courseId, topicId, buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_VIDEO_DUMP: {
            const { topicId, courseId, videoId, videoAction, tokenType } = args
            return dumpVideoForBuddies({ userBuddies, topicId, courseId, videoId, videoAction, tokenType,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_CHAT_DUMP: {
            const { topicId, learningObjectiveId, courseId, chatAction } = args
            return dumpChatForBuddies({ userBuddies, topicId, learningObjectiveId, courseId, chatAction,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_PQ_DUMP: {
            const { topicId, courseId, learningObjectiveId, pqDumpInput, tokenType } = args
            return dumpPracticeQuestionForBuddies({ userBuddies, topicId, courseId, learningObjectiveId, pqDumpInput, tokenType,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_BLOCKBASEDPROJECT_DUMP: {
            const { topicId, projectId, courseId, blockBasedProjectInput } = args
            return dumpBlockBasedProjectForBuddies({ userBuddies, topicId, projectId, courseId, blockBasedProjectInput,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_BLOCKBASEDPRACTICE_DUMP: {
            const { topicId, projectId, courseId, blockBasedPracticeInput } = args
            return dumpBlockBasedPracticeForBuddies({ userBuddies, topicId, projectId, courseId, blockBasedPracticeInput,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_ASSIGNMENT_DUMP: {
            const { topicId, courseId, codingAssignmentInput, tokenType } = args
            return dumpCodingAssignmentForBuddies({ userBuddies, topicId, courseId, codingAssignmentInput, tokenType,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_COMICSTRIP_DUMP: {
            const { learningObjectiveId, courseId, comicStripInput } = args
            return dumpComicStripForBuddies({ userBuddies, learningObjectiveId, courseId, comicStripInput,buddyQueriesTracker })
        }
        case buddyQueries.USER_VIDEOS: {
            const { topicId, courseId, videoId, tokenType } = args
            return fetchUserVideosForBuddies({ userBuddies, topicId, courseId, videoId, tokenType, buddyQueriesTracker})
        }
        case buddyQueries.USER_ASSIGNMENTS: {
            const { topicId, tokenType } = args
            return fetchUserAssignmentForBuddies({ userBuddies, topicId, tokenType,buddyQueriesTracker })
        }
        case buddyQueries.ADD_USER_ACTIVITY_LEARNINGSLIDE_DUMP: {
            const { learningSlideConnectId, courseConnectId, topicConnectId, learningObjectiveConnectId, learningSlideInput } = args
            return dumpLearningSlideForBuddies({ userBuddies, learningSlideConnectId, courseConnectId, topicConnectId, learningObjectiveConnectId, learningSlideInput,buddyQueriesTracker })
        }
        case buddyQueries.USER_BLOCKBASED_PRACTICES: {
            const { topicId, blockBasedPracticeId, courseId, tokenType } = args
            return fetchBlockBasedPracticeForBuddies({ userBuddies, topicId, courseId, blockBasedPracticeId, tokenType,buddyQueriesTracker})
        }
        case buddyQueries.USER_BLOCKBASED_PROJECTS: {
            const { topicId, blockBasedProjectId, courseId, tokenType } = args
            return fetchBlockBasedProjectsForBuddies({ userBuddies, topicId, courseId, blockBasedProjectId, tokenType,buddyQueriesTracker })
        }
        default: {
            throw new Error('Unhandled type of query for buddy!')
        }
    }
}

export default buddyQueriesCaller