import React from 'react'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'
import { ContactUs, Monitor, Outlined, StudentSvg, CalendarSvg, CourseSvg, Reports, Settings } from '../../../constants/icons'
import '../SplitScreen.scss'
import cx from 'classnames'
import getThemeColor from '../../../utils/teacherApp/getThemeColor'

const Item = (props) => {
    return <Link to={props.to} className={cx('navitem-link', props.isActive ? `navitem-link-active` : '')}>
        {props.children}
    </Link>
}


const renderItem = ({ title, isActive, iconType, route, icon, style, toggleMobileSidebarOpened, comingSoon, componentToDisplay: Component, newestLaunch, noLink = false,props }) => noLink ? (
    <div>Inactive</div>
) : (
    <Item
        isActive={isActive}
        to={route}
        originalProps={props}
    >
        {
            <div 
                className='navitem-link-image' 
                // style={{ marginRight: '12px', position: 'relative', top: '2px', fontFamily: 'Inter' }}
            >
                <Component color={isActive?getThemeColor():'#333333'} />
            </div>
        }
        <span 
            className='navitem-link-title'
            // style={{ fontSize: '15px' }}
        >
            {title}
        </span>
        {newestLaunch && (
            <Item.NewTag>
                New
            </Item.NewTag>
        )}
    </Item>
)

const NavItem = ({ title, isActive, iconType, route, icon, style, toggleMobileSidebarOpened, comingSoon, newestLaunch, noLink,props}) => {
    let componentToDisplay = null
    switch (iconType) {
        case 'dashboard':
            componentToDisplay = Outlined
            break
        case 'classroom':
            componentToDisplay = Monitor
            break
        case 'student':
            componentToDisplay = StudentSvg
            break
        case 'timeTable':
            componentToDisplay = CalendarSvg
            break
        case 'course':
            componentToDisplay = CourseSvg
            break
        case 'reports':
            componentToDisplay = Reports
            break
        case 'settings':
            componentToDisplay = Settings
            break
        case 'contact':
            componentToDisplay = ContactUs
            break
        default:
            componentToDisplay = null
            break
    }
    if (comingSoon) {
        return (
            <div className=''>
                ToolTip component
            </div>
        )
    }
    return renderItem({ title, isActive, iconType, route, icon, style, toggleMobileSidebarOpened, comingSoon, componentToDisplay, newestLaunch, noLink,props })
}

export default withRouter(NavItem)
