import React, { Component } from 'react'
import { withRouter, Route, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { capitalize, get } from 'lodash'
import cx from 'classnames'
import qs from 'query-string'
import isMobile from '../../utils/isMobile'
import NavBar from '../NavBar'
import SideNavBar from '../SideNavBar'
import UpdatedSideNavBar from '../UpdatedSideNavBar'
import './Route.scss'
import PopUp from '../../components/PopUp/PopUp'
import { filterKey } from '../../utils/data-utils'
import { Map } from 'immutable'
import SplitScreen from '../TeachersAppSideNav'
import { STUDENT_APP, getTeacherAppRoute } from '../../navItems'
import classNames from 'classnames'
import { isIPad } from '../../utils/is32BitArch'
import checkForEmbed, { checkIfEmbedEnabled } from '../../utils/teacherApp/checkForEmbed'
import mixPanelRoutesToElementMap, { appNames, mixPanelEvents } from '../../constants/mixpanel/mixPanelConst'
import mixpanelTrack from '../../utils/mixpanel-actions'
import { getCodePlayground } from '../../utils/getCourseId'
class RouteWithNav extends Component {
  constructor(props) {
    super(props);
    const _this = this;
    this.state = {
      width: typeof window === 'undefined' ? 1920 : window.innerWidth,
      showHamMenu: false,
      startTime: new Date(),
      endTime: null,
      isMixPanelEventTriggered: false,
    }
    // this.mutationObserver = typeof window !== 'undefined' && new MutationObserver((mutations) => {
    //   mutations.forEach((mutation) => _this.mutationObserverCallback(mutation));
    // });
  }

  /**
   * @param {string} path
   */
  replaceParameters = path => {
    return path.replace(new RegExp(':', 'g'), '')
  }
  getActiveParent = (activeParent) => {
    if (this.props.revisitRoute) {
      return '/sessions'
    }
    return activeParent && activeParent.route
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      const { innerWidth } = window
      if (this.state.width !== innerWidth) {
        this.setState({
          width: innerWidth
        })
      }
    })
    // const sessionDivIndentifier = document.querySelector("#root")
    // if (this.mutationObserver) {
    //   this.mutationObserver.observe(sessionDivIndentifier, {
    //     subtree: true,
    //     childList: true
    //   })
    // }
  }

  mutationObserverCallback = (mutation) => {
    if (get(mutation, 'target') && !this.state.isMixPanelEventTriggered) {
      let routeLink = this.getRouteLink()
      const element = routeLink && mixPanelRoutesToElementMap[routeLink]
      if (element && get(element, 'pageTitle') && get(element, 'pageIdentifier')) {
        let { pageTitle, pageIdentifier } = element
        const mixPanelDiv = get(mutation, 'target').querySelector(`.${pageIdentifier}`)
        if (mixPanelDiv) {
          this.setState({ endTime: new Date(), isMixPanelEventTriggered: true }, () => {
            const { loggedInUser, studentProfile } = this.props
            const user = loggedInUser && loggedInUser.toJS()
            if (get(user, 'id', '')) {
              const { startTime, endTime } = this.state
              if (routeLink === 'code-playground') {
                let language = ''
                  if(get(qs.parse(window.location.search), 'language')) {
                    language = get(qs.parse(window.location.search), 'language'); 
                  }
                  if (!language) language = getCodePlayground()
                  if (language) pageTitle = `${capitalize(language)} Code Playground`
              }
              mixpanelTrack(mixPanelEvents.PAGE_VIEWED, {
                  loggedInUser: loggedInUser && loggedInUser.toJS(),
                  studentProfile: studentProfile && get(studentProfile.toJS(), '[0]'),
                  startTime,
                  endTime,
                  pageTitle: pageTitle || routeLink
              }, appNames.STUDENT_APP)
            }
          })
        }
      }
    }
  }

  // componentWillUnmount = () => {
  //   if (this.mutationObserver) this.mutationObserver.disconnect()
  // }

  checkIfNotReview = () => {
    const currRoute = get(this.props, 'location.pathname')
    if (currRoute && (currRoute.includes('sessions/quiz-report-latest') || currRoute.includes('/quiz') || currRoute.includes('/codingAssignment') || (currRoute.includes('/practice') && !currRoute.includes('sessions/practice')))) {
      return false
    }
    return true
  }

  componentDidUpdate(prevProps) {
    const prevRoute = get(prevProps, 'location.pathname')
    const currRoute = get(this.props, 'location.pathname')
    if ((prevRoute !== currRoute) && (currRoute && currRoute.startsWith('/sessions/')) && this.checkIfNotReview()) {
      localStorage.setItem('prevRoute', currRoute)
    }
    if (!this.checkIfNotReview()) {
      localStorage.setItem('prevRoute', '')
    }
    if (prevRoute !== currRoute) {
      this.setState({
        isMixPanelEventTriggered: false,
        startTime: new Date(),
        endTime: null
      })
    }
  }

  getRouteLink = () => {
    let routeLink = get(this.props, 'computedMatch.path', '')
    let routeParams = Object.keys(get(this.props, 'computedMatch.params', {}) || {}).map(routeParams => `/:${routeParams}`)
    let isHomework = false
    if (routeLink && routeLink !== '/sessions' && routeLink !== '/homework') {
      if (routeLink.includes('/revisit')) routeParams.push('/revisit')
      if (routeLink.includes('/sessions')) routeParams.push('/sessions')
      if (routeLink.includes('/homework')) {
        isHomework = true
        routeParams.push('/homework')
      }
    }
    routeParams.forEach(route => {
      routeLink = routeLink.replace(route, '')
    })
    routeLink = routeLink.replace('/', '')
    if (isHomework) {
      routeLink = `homework-${routeLink}`
    }
    return routeLink
  }

  toggleHamMenu = () => {
    if (this.state.showHamMenu) {
      this.setState({
        showHamMenu: false
      })
    } else {
      this.setState({
        showHamMenu: true
      })
    }
  }

  renderMenuItems = (menu, path, activeItem) => {
    return (
      <div style={{ height: '100%' }}>
        {menu.map(item => {
          if (item.onClick) {
            return (
              <div
                className={cx('route-menuItems', (path == item.route || activeItem === item.route) ? 'route-activeBackground' : '')}
                onClick={() => {
                  this.setState({ showHamMenu: false })
                  item.onClick()
                }}
                style={{ cursor: 'pointer' }}
              >
                <div className={'route-text'}>
                  {item.name}
                </div>
              </div>
            )
          }
          if (item.showInHamMenu) {
            return (
              <Link
                className={cx('route-menuItems', (path == item.route || activeItem === item.route) ? 'route-activeBackground' : '')}
                to={item.route}
                onClick={() => {
                  this.setState({
                    showHamMenu: false
                  })
                }}>
                <div className={'route-text'}>
                  {item.name}
                </div>
              </Link>
            )
          }
        })}
      </div>

    )
  }

  isSSR = () => {
    if (typeof window === 'undefined') return true
    if (window.__SSR__) return true
    return false
  }

  render() {
    const { navItem, sideNavItem, withUpdatedSideNav, noMobileHeader, ...props } = this.props
    const { path } = props.computedMatch || props.match
    const navConfig = props.navItems.filter(navItem => navItem.navItem)
    const navConfigMobile = [
      ...props.navItems.filter(navItem => navItem.showInHamMenu),
      { name: 'Published Code', route: '/selected-code/stats', showInHamMenu: true },
      { name: 'Student Community', route: '/student-community', showInHamMenu: true },
      { name: 'Code Playground', route: '/code-playground', showInHamMenu: true },
      { name: 'Cheat Sheet', route: '/cheatsheet', showInHamMenu: true },
      { showInHamMenu: true, onClick: () => { this.props.dispatch({ type: 'LOGOUT' }) }, name: 'Logout' }
    ]
    const activeParent = props.navItems.find(navItem => {
      if (navItem.name === props.parent) {
        return true
      }
      return false
    })
    if ((props.hideNavLoggedOut && props.loggedInUser.size === 0)) {
      if (props.bodyContainerFull) {
        return (
          <div>
            <div className={'route-bodyContainerFull'}>
              <Route {...props} component={props.component} />
            </div>
          </div>
        )
      }
      return (
        <Route {...props} component={props.component} />
      )
    }
    const user = this.props.user && this.props.user.toJS()
    const isTimeTableEnabled = get(user, 'mentorProfile.schools[0].isTimeTableEnabled', false) || get(user, 'rawData.mentorProfile.schools[0].isTimeTableEnabled', false) 
    const isClassroomEnabled = get(user, 'mentorProfile.schools[0].isClassroomEnabled', false) || get(user, 'rawData.mentorProfile.schools[0].isClassroomEnabled', false)
    const isUserIsAllowedAccess=()=>{
      const userRole=get(user,'role')
      const userSecondaryRole = get(user, 'secondaryRole')
      if(props.managementApp){
        const routeBeingTriedToAccess=path
        const doesRouteExist = getTeacherAppRoute({ isTimeTableEnabled, isClassroomEnabled }).find(routeObj=>routeObj.route===routeBeingTriedToAccess)
        if(doesRouteExist){
          if(userRole==='mentor'){
            if(userSecondaryRole===undefined) return false
            return true
          }else{
            const isAllowed= get(doesRouteExist,'allowedRoles').includes(userRole) || get(doesRouteExist,'allowedRoles').includes(userSecondaryRole)
            if(!isAllowed) return false
          }
        }
      }
     return true
    }
    if (props.managementApp) {
      if (props.noTeacherAppSideNav) {
        return <Route {...props} path={props.path} component={props.component} />
      }
      return <SplitScreen {...props} component={props.component} navItems={props.studentAppNavItems ? STUDENT_APP : getTeacherAppRoute({ isTimeTableEnabled, isClassroomEnabled })} />
    }
    return (
      <>
        <div>
          {!props.noNav &&
            <NavBar
              navConfig={navConfig} path={this.replaceParameters(path)}
              activeParentRoute={this.getActiveParent(activeParent)}
              toggleHamMenu={this.toggleHamMenu}
              noMobileHeader={noMobileHeader || false}
            />
          }
          <div className={classNames({
            'route-container': !isMobile(),
            '__IPad__': isIPad()
          })}>
            {isIPad() && (
              <div className='rotateDevice'>
                <span className='rotateDeviceIcon' />
                OH! no! Rotate your Device to <br />Landscape mode!
              </div>
            )}
            {withUpdatedSideNav && !isMobile() &&
              <UpdatedSideNavBar
                computedMatch={this.props.computedMatch || this.props.match}
                parent={this.props.parent}
                revisitRoute={this.props.revisitRoute}
              />
            }
            {props.sideNav &&
              <SideNavBar
                computedMatch={this.props.computedMatch || this.props.match}
                parent={this.props.parent}
                revisitRoute={this.props.revisitRoute}
              />
            }
            <div className={
              cx(
                props.noNav ? 'route-bodyContainerFull' : 'route-bodyContainer',
                props.noNav && props.noOverflow ? '' : 'route-bodyOverflowOverlay',
                withUpdatedSideNav ? 'route-bodyPositionUnset' : '',
                this.props.className,
                (checkForEmbed() || checkIfEmbedEnabled()) ? 'session-embed-full' : '',
              )
            }
              style={{
                background: `${props.background ? props.background : ''}`
              }}
              id="tk-route-container"
            >
              {
                this.state.width < 1000
                  ? (
                    <div>
                      <PopUp
                        showPopup={this.state.showHamMenu}
                        closePopUp={() => this.setState({ showHamMenu: false })}
                        ref={this.props.ref}
                        leftAlignedChildren
                      >
                        <div style={{ height: '100%' }}>
                          <div className={'route-sideBar'}>
                            {this.renderMenuItems(navConfigMobile, path, this.getActiveParent(activeParent))}
                          </div>
                        </div>
                      </PopUp>
                    </div>
                  ) : <div />
              }
              <Route {...props} component={props.component} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  loggedInUser: filterKey(state.data.getIn([
    'user',
    'data'
  ]), 'loggedinUser').get(0) || Map({}),
  studentProfile: state.data.getIn(['studentProfile','data'])
})
export default connect(mapStateToProps)(withRouter(RouteWithNav))
