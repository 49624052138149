import GqlClient from './GqlClient'
import errors from './errors'
import config from '../config'
import getAuthToken from './getAuthToken'
import { getHeadersForBuddy } from './buddyUtils'
import { getActiveBatchForHeaders } from './multipleBatch-utils'

const DEBUG_API_ERROR = false

const handleGraphqlResponseErrors = errordata => {
  let errorMessage
  if (DEBUG_API_ERROR) {
    console.error('apiError', errordata)
  }
  if (errordata.errors && errordata.errors.length && errordata.errors[0].name) {
    errorMessage = errordata.errors[0].name
  }
  if (errordata.message) {
    errorMessage = errordata.message
  }
  return errorMessage || errors.UnexpectedError
}

const client = new GqlClient({
  url: (process.env.REACT_APP_FORCE_CDN_CLIENT && config.cdnApiBaseURL) ? config.cdnApiBaseURL : config.apiBaseURL,
  errorHandler: handleGraphqlResponseErrors,
});
const cdnClient = new GqlClient({
  url: config.cdnApiBaseURL,
  errorHandler: handleGraphqlResponseErrors
})
const requestToGraphql = async (query, variables, token,  tokenType) => {
  let graphqlClient = client;
  if (((variables && variables.CDNCaching) || process.env.ENFORCE_CDN_CLIENT) && config.cdnApiBaseURL) {
    delete variables['CDNCaching']
    graphqlClient = cdnClient;
  }
  const additionalHeaderVariables = { ...getHeadersForBuddy(tokenType), ...getActiveBatchForHeaders(tokenType) }
  if (tokenType) {
    return graphqlClient.query(query, variables, {
      headers: {
        authorization: getAuthToken(tokenType),
        ...additionalHeaderVariables,
      },
    });
  }
  if (!token) {
    token = getAuthToken()
  }
  return graphqlClient.query(query, variables, {
    headers: {
      authorization: token,
      ...additionalHeaderVariables,
    },
  });
}

export default requestToGraphql
