import config from '../config'

const getPath = file => {
  if (file && file.includes) {
    /** if the file already has the fileBaseUrl information i.e,
     * in the case of a updated file simply return the uri else add the fileBaseUrl */
    if (file.includes(config.fileBaseUrl) || file.includes(config.cloudFrontBaseUrl)) {
      return file
    }
    /** Not appending Date here helps in caching of files */
    return `${config.cloudFrontBaseUrl}/${file}`
  }
  return ''
}

export default getPath
