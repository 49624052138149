import { Map } from 'immutable'
import { get } from 'lodash'
import { filterKey } from '../data-utils'

const checkForEmbed = () => {
  if (typeof window !== 'undefined') {
    return get(window, 'fromTeachersApp', false)
  }
  return false;
}

export const getEmbedData = (name) => {
  if (typeof window !== "undefined") {
    return get(window, `fromTeachersApp.embedData.${name}`);
  }
  return false;
}

export const checkIfEmbedEnabled = () => {
  if (typeof window !== "undefined") {
    const user = filterKey( window && window.store.getState().data.getIn(['user', 'data']), 'loggedinUser').get(0) || Map({})
    return user && get(user.toJS(), 'fromTeachersAppEmbed')
  }
  return false;
}

export const isPqReportNotAllowed = () =>
  checkForEmbed() && ['true', 'True', true].includes(getEmbedData("isRevisit")) && getEmbedData("sessionStatus") && getEmbedData("sessionStatus") !== 'completed'

export default checkForEmbed;