import get from "lodash/get"
import config, { HOUR_BEFORE_START_SESSION, PYTHON_COURSE_BACKEND } from "../../../config"
import duck from "../../../duck"
import fetchBatchDetails from "../../../queries/fetchBatchDetails"
import fetchLiveSession, { fetchPrevSession } from "../../../queries/fetchLiveSession"
import verifyOTP from "../../../queries/SchoolLIveLoginInput"
import { getHomeworkRoute, getInSessionRoute, } from "../../UpdatedSessions/utils"
import requestToGraphql from "../../../utils/requestToGraphql"
import getTopicsComponentQuery from "../../../queries/getTopicsData"
import { getSlotDifference, getTimeRangeFromSession } from "../../../utils/teacherApp/mapQueryResponseToFullCalendarEvents"
import fetchUserCourses from "../../../queries/sessions/fetchUserCourses"
import fetchCourseDetails from "../../../queries/sessions/fetchCourseDetails"
import gql from "graphql-tag"
import { getAppUserAuthToken } from "../../../utils/data-utils"
import { buddyQueryTracker } from "../../../constants/buddyLogin"
import { sessionStatus as sessionStatusValues } from '../../TeacherApp/pages/TimeTable/constants'
import { getActiveBatchDetail } from "../../../utils/multipleBatch-utils"
import startBatchSession from "../../../queries/teacherApp/startBatchSession"
import redirectByUserType from "../../../utils/redirectByUserType"

export const verifyOTPHandler = async (userId, buddyLoginInput = [], setIsLoading) => {
    let verifyOTPStatus = await verifyOTP(userId, '', setIsLoading, buddyLoginInput)
    return verifyOTPStatus
}

const markPresent = async (studentId, batchSessionId) => {
    await requestToGraphql(`
    mutation {
        updateBatchSession(
          id: "${batchSessionId}"
          input: {
            attendance: {
              updateWhere:{
                studentReferenceId:"${studentId}"
              }
              updateWith:{
                status:present
                isPresent:true
              }
            }
          }
        ) {
          id
        }
      }`)
}

const updatedMarkAttendance = (sessionId, studentProfileIds) => {
    requestToGraphql(gql`{
    getBuddyStatus(sessionId:"${sessionId}", studentIds:${studentProfileIds}, action:"markAttendance"){
        error
        result
    }
    }`)
    return true;
}

export const logoutBuddies = (sessionId, studentProfileIds) => {
    requestToGraphql(gql`{
    getBuddyStatus(sessionId:"${sessionId}", studentIds:${studentProfileIds}, action:"logout"){
        error
        result
    }
    }`, {}, undefined, 'appTokenOnly')
    return true;
}

const startBatchSessionStatus = async (batchSessionId) => {
    const data = await requestToGraphql(`mutation {
    updateBatchSession(id: "${batchSessionId}", input:{
        sessionStatus:started
    }) {
        id
        sessionStatus
    }
    }
    `)
    return data;
}

export const renderHeader = (type, step,isStudentSelected,isAlreadyPresentInSystem,buddyTeamListLength, isRollNoAutoGenerated) => {
    if (type)
        return 'Login'
    switch (step) {
        case 0:
            return 'Enter your OTP'
        case 1:
            if(isStudentSelected) return 'All set to go?'
            if(isAlreadyPresentInSystem) return 'Confirm Password'
            return buddyTeamListLength===0?'Enter your '+(isRollNoAutoGenerated ? 'Name' : 'Roll Number'):'Enter Buddy #'+ (buddyTeamListLength+1) + "'s " + (isRollNoAutoGenerated ? 'Name' : 'Roll Number')
        case 2:
            return 'Choose your name'
        default:
            return
    }
}

export const loginChangeType = (loginWithEmail) => {
    switch (loginWithEmail) {
        case true:
            return 'OTP'
        case false:
            return 'email'
        default:
            return
    }
}

const checkIfFirstTopic = (currTopicId, firstTopicId) => {
    return firstTopicId === currTopicId
}

const routeToUpdatedSessions = ({
    topicComponentRule,
    course,
    currentSessionTopicId,
    prevSessionTopicId,
    prevTopicComponentRule = [],
    history,
    state = {} }) => {
        let redirectUrl = ''
        const { firstComponentName, childComponentName, componentId, ...restObj } = getInSessionRoute({
            topicComponentRule, course, topicId: currentSessionTopicId
        })
        redirectUrl = restObj.redirectUrl
        if (prevTopicComponentRule.length) {
            const isQuizExist = prevTopicComponentRule.find(component => get(component, 'componentName') === 'quiz')
            if (isQuizExist) {
                state = {
                    ...state,
                    firstComponent: {
                        componentName: firstComponentName,
                        childComponentName,
                        componentId
                    },
                    quizReportTopicId: prevSessionTopicId
                }
                const homeworkRedirectUrl = getHomeworkRoute({
                    topicComponentRule: prevTopicComponentRule,
                    course,
                    topicId: currentSessionTopicId,
                    showReportIfExist: true
                })
                if (homeworkRedirectUrl) {
                    redirectUrl = homeworkRedirectUrl
                }
            }
        }
        history.push({
            pathname: redirectUrl,
            state
        })
        return true
    }

const findPreviousTopic = (topics, currentTopicId) => {
    topics = topics.sort((topicOne, topicTwo) => topicOne.order - topicTwo.order)
    for (let topicPointer = 0; topicPointer < topics.length - 1; topicPointer++) {
        if (topics[topicPointer + 1].id === currentTopicId) {
            return topics[topicPointer]
        }
    }
    return {}
}

const getBuddyQueryTrackerObject=(buddyList=[])=>{
    const buddies=buddyList.filter(buddy=>!get(buddy,'isPrimaryUser'))
    const trackerObj={}
    buddies.forEach(buddy=>{
        trackerObj[get(buddy,'id')]=buddyQueryTracker
    })
    return trackerObj
}

export const moveToSession = async (data, override, history, code, setIsLoading, fromOtpScreen, { isBuddyLoginEnabled, sessionId }) => {
    const user = data
    let course
    let currentSessionTopicId
    let prevSessionTopicId
    let prevSessionCourseId
    let prevTopicComponentRule = []
    let role = get(data, 'role')
    if (get(user, 'isMentorLoggedIn')) {
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    if (role !== config.MENTEE && role !== config.PARENT) {
        // getToasterBasedOnType({
        //     type: 'error',
        //     message: 'Unauthorized access'
        // })
        setIsLoading(false)
        return false
    }
    if (!fromOtpScreen) {
        const shouldRedirect = await redirectByUserType(user)
        if (!shouldRedirect) history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    if (!get(user, 'studentProfile.batch.id')) {
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    const liveSessionDetails = await fetchLiveSession({
        batchId: get(user, 'studentProfile.batch.id'),
        code,
        sessionId,
    })

    if (!liveSessionDetails) {
        // setIsLoading(false)
        // return false
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    let sessionStatus = get(liveSessionDetails, 'sessionStatus')
    let isRetakeSession = get(liveSessionDetails, 'isRetakeSession', false)
    if (get(liveSessionDetails, 'sessionStatus') === sessionStatusValues.completed && !isRetakeSession && get(liveSessionDetails, 'schoolSessionsOtp[0].otp')
        && get(liveSessionDetails, 'retakeSessions', []).length) {
        const allottedRetakeSession = get(liveSessionDetails, 'retakeSessions', []).find(retakeSession => get(retakeSession, 'sessionStatus') === 'allotted')
        if (allottedRetakeSession) {
            isRetakeSession = true;
            startBatchSession(get(liveSessionDetails, 'id'), false, get(allottedRetakeSession, 'id'))
        }
    }
    if (!(get(liveSessionDetails, 'topic.topicComponentRule', []) || []).length) {
        history.push(`/sessions`, { fromLogin: "true" })
        return null
    }
    // const isTimeTableEnabledOfSchool = get(user, 'studentProfile.school.isTimeTableEnabled', false)
    if (liveSessionDetails) {
        const studentProfileIds = []
        if (get(user, 'studentProfile.id')) studentProfileIds.push(get(user, 'studentProfile.id'))
        if (get(user, 'buddyDetails') && get(user, 'buddyDetails', []).length) {
            currentSessionTopicId = get(liveSessionDetails, 'topic.id')
            course = get(liveSessionDetails, "course")
            const promises=[]
            localStorage.setItem('buddyQueriesTracker',JSON.stringify(getBuddyQueryTrackerObject(get(user, 'buddyDetails', []))))
            get(user, 'buddyDetails', []).forEach((buddy) => {
                if(!get(buddy,'isPrimaryUser')){
                    promises.push(requestToGraphql(`mutation{
                        userTopicJourney(topicId:"${currentSessionTopicId}",courseId:"${get(course,'id')}"){
                          topicStatus
                        }
                      }`,{},getAppUserAuthToken(get(buddy,'token'))))
                }
                if (!studentProfileIds.includes(get(buddy, 'studentProfile.id'))) {
                    studentProfileIds.push(get(buddy, 'studentProfile.id'))
                }
            })
            await Promise.allSettled(promises)
        }
        // if (!isTimeTableEnabledOfSchool && !get(liveSessionDetails, 'sessionStartedByMentorAt')) {
        //     // If time table is not enabled for the student`s school and batchSession is not started by mentor then redirect student to sessions page
        //     history.push(`/sessions`, { fromLogin: "true" })
        //     return null
        // }
        await fetchBatchDetails(user.id).call()
        const { startTime: sessionStartTime } = getTimeRangeFromSession(get(liveSessionDetails, 'bookingDate'), liveSessionDetails)
        const isBetweenOneHr = getSlotDifference(sessionStartTime, HOUR_BEFORE_START_SESSION)
        if (sessionStatus === 'allotted' && isBetweenOneHr) {
            const updatedSession = await startBatchSessionStatus(get(liveSessionDetails, 'id'))
            sessionStatus = get(updatedSession, 'data.updateBatchSession.sessionStatus')
        }
        await fetchUserCourses(get(user, "id"), false).call()
        if (sessionStatus === sessionStatusValues.allotted || (sessionStatus === sessionStatusValues.completed && !isRetakeSession)) {
            history.push(`/sessions`, { fromLogin: "true" })
            return null
        }
        if (studentProfileIds.length && get(liveSessionDetails, 'id')) {
            updatedMarkAttendance(get(liveSessionDetails, 'id'), JSON.stringify(studentProfileIds))
        }
        // markPresent(get(user, 'studentProfile.id'), get(liveSessionDetails, 'id'))
        if (sessionStatus === sessionStatusValues.completed && !isRetakeSession) {
            history.push(`/sessions`, { fromLogin: "true" })
            return null
        }
        currentSessionTopicId = get(liveSessionDetails, 'topic.id')
        course = get(liveSessionDetails, "course")
        const batchDetail = getActiveBatchDetail(get(user, 'studentProfile.batch'))
        const prevSessionDetails = await fetchPrevSession({ batchId: get(batchDetail, 'id'), bookingDate: get(liveSessionDetails, 'bookingDate') })
        const filteredPrevSessionDetail = prevSessionDetails.filter(session => get(session, 'topic.id') !== currentSessionTopicId)
        if (filteredPrevSessionDetail.length) {
            prevSessionCourseId = get(filteredPrevSessionDetail, '[0].course.id')
            prevSessionTopicId = get(filteredPrevSessionDetail, '[0].topic.id')
            prevTopicComponentRule = get(filteredPrevSessionDetail, '[0].topic.topicComponentRule', [])
        }
        await requestToGraphql(getTopicsComponentQuery(currentSessionTopicId, get(course, 'id')))
        fetchCourseDetails(course.id, true).call()
    }
    if (override) {
        if (get(user, "id")) {
            duck.merge(
                () => ({
                    user: {
                        ...user,
                        hasVerifiedOTP: true,
                        fromOtpScreen
                    },
                }),
                { key: "loggedinUser" }
            );
        }
        if (!liveSessionDetails) {
            history.push(`/sessions`, { fromLogin: "true" })
            return null
        }
        let isPythonCourseTitle = get(course, 'title') === PYTHON_COURSE_BACKEND
        if (isPythonCourseTitle) {
            if (checkIfFirstTopic(currentSessionTopicId, get(course, 'topics[0].id'))) {
                history.push({
                    pathname: `/sessions/video/${currentSessionTopicId}`,
                    state: {
                        quizReportTopicId: currentSessionTopicId
                    },
                    fromLogin: true
                })
            }
            else {
                history.push({
                    pathname: `/sessions/quiz-report-latest/${currentSessionTopicId}`, state: {
                        quizReportTopicId: get(findPreviousTopic([...get(course, 'topics')], currentSessionTopicId), 'id', null),
                    }, fromLogin: true
                })
            }
            return null
        }

        else {
            routeToUpdatedSessions({
                topicComponentRule: get(liveSessionDetails, 'topic.topicComponentRule', []),
                course,
                currentSessionTopicId,
                prevSessionTopicId,
                prevTopicComponentRule,
                history
            })
            return null
        }
    }
    else return false
}


export const getUserForStateLoginWithEmailOrUsername = (data, fromOtpScreen, callbackIfMultipleChildren = () => { }) => {
    if (get(data, 'studentProfile.id')) {
        return {
            user: {
                ...data,
                fromOtpScreen,
                campaign: get(data, 'campaign.type'),
                savedPassword: get(data, 'studentProfile.parents[0].user.savedPassword'),
                email: get(data, 'studentProfile.parents[0].user.email'),
                parent: {
                    ...get(data, 'studentProfile.parents[0].user'),
                    parentProfile: get(data, 'studentProfile.parents[0]'),
                },
                createdAt: get(data, 'createdAt')
            },
            studentProfile: get(data, 'studentProfile')
        }
    }
    const children = get(data, 'children')
    const childrenStudentProfile = get(data, 'parentProfile.children[0]')
    if (children.length && children.length === 1) {
        return {
            user: {
                ...children[0],
                fromOtpScreen,
                studentProfile: { ...childrenStudentProfile },
                savedPassword: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.savedPassword') : get(data, 'savedPassword'),
                campaign: get(data, 'campaign.type'),
                email: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.email') : get(data, 'email'),
                parent: data,
                createdAt: get(data, 'createdAt')
            },
            studentProfile: childrenStudentProfile
        }
    }
    if (children.length && children.length > 1) {
        if (callbackIfMultipleChildren) {
            callbackIfMultipleChildren()
        }
        return {
            userChildren: children,
            userParent: data,
            user: {
                ...children[0],
                fromOtpScreen: fromOtpScreen,
                campaign: get(data, 'campaign.type'),
                email: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.email') : get(data, 'email'),
                parent: data,
                createdAt: get(data, 'createdAt'),
                savedPassword: fromOtpScreen ? get(data, 'studentProfile.parents[0].user.savedPassword') : get(data, 'savedPassword'),
            }
        }
    }
}
