import { get } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { appNames, mixPanelEvents } from '../constants/mixpanel/mixPanelConst';
import { getActiveBatchDetail } from './multipleBatch-utils';

const mixpanelTrack = (eventName, props, appName) => {
    const isProduction = process.env.REACT_APP_NODE_ENV === 'production'
    if (!isProduction || !appName) return;
    const mixPanelPropsObj = {}
    const { STUDENT_APP } = appNames
    switch (appName) {
        case STUDENT_APP:
            if (get(props, 'loggedInUser')) {
                const loggedInUser = get(props, 'loggedInUser')
                mixPanelPropsObj['Student Name'] = get(loggedInUser, 'name', '')
                mixPanelPropsObj['Student Id'] = get(loggedInUser, 'id', '')
            }
            if (get(props, 'studentProfile')) {
                const studentProfile = get(props, 'studentProfile')
                const grade = get(studentProfile, 'grade', '') ? get(studentProfile, 'grade', '').replace('Grade', '') && Number(get(studentProfile, 'grade', '').replace('Grade', '')) : ''
                mixPanelPropsObj['Grade'] = grade
                mixPanelPropsObj['Section'] = get(studentProfile, 'section', '')
                mixPanelPropsObj['Roll No'] = get(studentProfile, 'rollNo', '')
                mixPanelPropsObj['School Name'] = get(studentProfile, 'school.name', '')
                const studentActiveBatch = getActiveBatchDetail(get(studentProfile, 'batch'))
                mixPanelPropsObj['Course Package'] = get(studentActiveBatch, 'coursePackage.title')
                mixPanelPropsObj['Business type'] = get(studentActiveBatch, 'type') ? get(studentActiveBatch, 'type') === 'normal' ? 'B2C' : get(studentActiveBatch, 'type', '').toUpperCase() : ''
            }
            if (get(props, 'startTime') && get(props, 'endTime')) {
                let timeTakenInSec = (new Date(get(props, 'endTime')).getTime() - new Date(get(props, 'startTime')).getTime()) / 1000
                timeTakenInSec = Number(timeTakenInSec).toFixed(2)
                mixPanelPropsObj['Page Load Time (Sec)'] = Number(timeTakenInSec)
            }
            if (get(props, 'pageTitle')) mixPanelPropsObj['Page Title'] = get(props, 'pageTitle')
            const netSpeedInMbps = (window && localStorage.getItem("netSpeedInMbps")) || ''
            if (netSpeedInMbps) mixPanelPropsObj['Net Speed (Mbps)'] = netSpeedInMbps
            mixPanelPropsObj['App Name'] = appName;
            Object.keys(mixPanelPropsObj).forEach(key => {
                if (!mixPanelPropsObj[key]) delete mixPanelPropsObj[key]
            })
            if (isProduction && process.env.REACT_APP_MIXPANEL_PROJECT) {
                mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT, {
                    debug: !isProduction,
                    ignore_dnt: true
                });
                const { PAGE_VIEWED } = mixPanelEvents;
                switch (eventName) {
                    case PAGE_VIEWED:
                        mixpanel.track(eventName, mixPanelPropsObj);
                        break;
                    default:
                        break;
                }
            }
            break;
        default:
            break;
    }
}

export default mixpanelTrack;