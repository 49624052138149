import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { Link, Route } from "react-router-dom";
import { List } from "immutable";
import { useLocation, withRouter } from "react-router";
import isMobile, { isCRTScreen } from "../../utils/isMobile";
import getPath from "./../../utils/getPath";
import FlexContainer from "./components/FlexContainer";
import Nav from "./components/Nav";
import NavItem from "./components/NavItem";
import ProfileContainer from "./components/ProfileContainer";
import Role from "./components/Role";
import UserDetails from "./components/UserDetails";
import UserImage from "./components/UserImage";
import "./SplitScreen.scss";
import LogoutModal from "./components/logoutConfirmationModal";
import LogoutIcon from "../../assets/teacherApp/classroom/log-out-outline.svg";
import { filterKey } from "../../utils/data-utils";
import duck from "../../duck";
import getThemeColor from "../../utils/teacherApp/getThemeColor";
import cx from 'classnames'
import { MOBILE_BREAKPOINT } from "../../config";
import { Helmet } from "react-helmet";


const isStudentProfilePresent = () => {
  if (window !== undefined) {
    const user =
      filterKey(
        window && window.store.getState().data.getIn(["user", "data"]),
        "loggedinUser"
      ) || List([]);
    const loginData = get(user.toJS()[0], "rawData");
    if (get(loginData, "mentorProfile.studentProfile")) {
      return true;
    }
    return false;
  }
  return false;
};

function SideNavIconVersion({ setVisibleSideNav, visibleSideNav }) {
  return (
    <>
      <div
        className={`menu__icon__container ${visibleSideNav ? 'open' : 'close'} `}
        onClick={() => setVisibleSideNav()}
      >
        <svg focusable="false" viewBox="0 0 24 24"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
        {/* <div className={cx('teacher-app-hamburger', visibleSideNav ? 'open' : 'close')}>
          <span></span>
          <span></span>
          <span></span>
        </div> */}
        {/* <div
          className={cx('teacher-app-cn-navbar', visibleSideNav ? 'open' : 'close', 'mobile_nav_hide')}
        >
          <div className='teacher-app-cn-navbar-line lines-1'>
            <div className='teacher-app-cn-navbar-line-1'></div>
            <div className='teacher-app-cn-navbar-line-2'></div>
          </div>
          <div className='teacher-app-cn-navbar-line lines-2'>
            <div className='teacher-app-cn-navbar-line-1'></div>
            <div className='teacher-app-cn-navbar-line-2'></div>
          </div>
          <div className='teacher-app-cn-navbar-line lines-3'>
            <div className='teacher-app-cn-navbar-line-1'></div>
            <div className='teacher-app-cn-navbar-line-2'></div>
          </div>
        </div> */}
      </div>
    </>
  );
}

const switchToStudentApp = (props, shouldRedirect = true) => {
  if (window !== undefined) {
    const user =
      filterKey(
        window && window.store.getState().data.getIn(["user", "data"]),
        "loggedinUser"
      ) || List([]);
    // window.store.dispatch({ type: 'LOGOUT' })
    const loginData = get(user.toJS()[0], "rawData");
    const { ...parent } = loginData;
    duck.merge(
      () => ({
        user: {
          ...get(loginData, "children[0]"),
          isMentorLoggedIn: true,
          parent: {
            ...parent,
            parentProfile: {
              children: [get(loginData, "mentorProfile.studentProfile.user")],
            },
          },
          email: loginData.email,
          createdAt: loginData.createdAt,
          rawData: loginData,
          routedFromTeacherApp: true,
          fromTeachersAppEmbed: !shouldRedirect,
        },
        userParent: parent,
      }),
      {
        key: "loggedinUser",
      }
    );
    if (shouldRedirect) props.history.replace("/sessions");
  }
};

const SplitScreen = (props) => {
  const profilePic = get(props.loggedInUser.toJS(), "profilePic.uri");
  const userName = get(props.loggedInUser.toJS(), "name");
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [visibleSideNav, setVisibleSideNav] = useState(true);
  const [isAllowed, setIsAllowed] = useState(true)
  const routePath=useLocation()

  const currentPath = get(props, "path", "");
  const handleStateChange = () => {
    if (window !== undefined) {
      const user =
        filterKey(
          window && window.store.getState().data.getIn(["user", "data"]),
          "loggedinUser"
        ) || List([]);
      // window.store.dispatch({ type: 'LOGOUT' })
      if (get(props, "path") === "/session-embed") {
        switchToStudentApp(props, false);
      } else {
        let loginData;
        if (get(user.toJS()[0], "role") === "schoolAdmin") {
          loginData = get(user.toJS()[0], "schoolAdmin");
        } else {
          loginData = get(user.toJS()[0], "rawData");
        }
        const { ...parent } = loginData;
        duck.merge(
          () => ({
            user: {
              ...parent,
              schoolTeacher: parent,
              rawData: parent,
              routedFromTeacherApp: false,
            },
            userParent: parent,
          }),

          {
            key: "loggedinUser",
          }
        );
      }
      if (!visibleSideNav && get(props, "path") === '/time-table') {
        setVisibleSideNav(true)
      }
    }
  }
  useEffect(() => {
    window.addEventListener("popstate", handleStateChange)
    handleStateChange()
    return () => window.removeEventListener("popstate", handleStateChange)
  }, [get(props, "path")]);
  useEffect(() => {
    if (window.innerWidth < MOBILE_BREAKPOINT) {
      setIsAllowed(false)
    }
    if (isCRTScreen()) {
      setVisibleSideNav(false)
    }
    const resizeOperation = () => {
      if (window.innerWidth < MOBILE_BREAKPOINT) {
        setIsAllowed(false)
      } else setIsAllowed(true)
      if (isCRTScreen()) {
        setVisibleSideNav(false)
      }
    }
    window.addEventListener("resize", resizeOperation)
    return () => window.removeEventListener("resize", resizeOperation)
  }, [])
  useEffect(() => {
    getThemeColor();
  }, []);
  if (!isAllowed) {
    return (
      <div className="splitScreen notAllowedScreen">
        <h1>Please Open App in Desktop</h1>
      </div>
    )
  }
  return (
    <>
      <Helmet>
        <title>Teacher App - Tekie</title>
      </Helmet>
      {isCRTScreen() && <div onClick={() => setVisibleSideNav(!visibleSideNav)} className={`splitScreen-withCRT-overlay ${visibleSideNav && 'open'}`}></div>}
      <div className="splitScreen">
        <div
          id='splitScreen-sidenav-Id'
          className={`splitScreen-sidenav ${(isMobile() || !visibleSideNav) && 'hamburger'} ${isCRTScreen() && 'splitScreen-sidenav-withCRT'}`
          }
        >
          <Nav>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
              }}
            >
              <Link to={props.to} className="logo-link">
                <img src="../../tekieLogo.png" alt="tekie logo" />
              </Link>
            </div>

            <ProfileContainer>
              <FlexContainer>
                <UserImage>
                  {profilePic ? (
                    <img src={getPath(profilePic)} alt="user" />
                  ) : userName ? (
                    userName[0].toUpperCase()
                  ) : (
                    ""
                  )}
                </UserImage>
                <UserDetails>
                  <span className="user-name">{userName}</span>
                  <Role>CS Teacher</Role>
                </UserDetails>
              </FlexContainer>
              {/* <ArrowUpDown /> */}
            </ProfileContainer>
            {props.navItems &&
              props.navItems.map(({ title, isActive, iconType, route }) => {
                return (
                  <NavItem
                    props={props}
                    title={title}
                    isActive={route.includes(props.activeRoute)}
                    iconType={iconType}
                    route={route}
                  />
                );
              })}
            <div className="divider"></div>
            {/* {isStudentProfilePresent() ? (
                <span
                  className="switch-btn navitem-link navitem-link-secondary"
                  onClick={() => {
                    switchToStudentApp(props);
                  }}
                >
                  Switch To Learning App
                </span>
              ) : null} */}
          </Nav>
          <span
              className="logout-btn navitem-link"
              onClick={() => setIsLogoutModalVisible(true)}
            >
              <img src={LogoutIcon} alt={"Logout icon"} /> Logout
            </span>
        </div>
        <div style={{overflow:get(routePath,'pathname').includes('time-table')?'hidden':'scroll'}} id='splitScreen-main-container' className={`splitScreen-main-component ${!visibleSideNav && 'hamburger'} ${isCRTScreen() && 'splitScreen-main-component-withCRT'}`}>
          {get(props, "path") !== '/time-table' || (get(props, "path") === '/time-table' && isCRTScreen()) ?
            <SideNavIconVersion setVisibleSideNav={() => setVisibleSideNav(!visibleSideNav)} visibleSideNav={visibleSideNav} /> :
            null}
          <Route {...props} component={props.component} />
        </div>
        {isLogoutModalVisible && (
          <LogoutModal setIsLogoutModalVisible={setIsLogoutModalVisible} />
        )}
      </div>
    </>
  );
};

export default withRouter(SplitScreen);
