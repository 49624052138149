import * as React from "react"

function SvgComponent(props) {
    return (
        <svg width="100%" height="100%" viewBox="0 0 42.104 42.209" {...props}>
            <g data-name="Capa 1" transform="translate(-.455 -.351)">
                <circle
                    data-name="Ellipse 1198"
                    cx={21}
                    cy={21}
                    r={21}
                    transform="translate(.455 .455)"
                    fill="#ffe14d"
                />
                <path
                    data-name="Path 34049"
                    d="M42.555 21.45a21.124 21.124 0 00-21.1-21.1v42.209a21.124 21.124 0 0021.1-21.109z"
                    fill="#fb3"
                />
                <path
                    data-name="Path 34050"
                    d="M21.455 37.53A16.076 16.076 0 1137.53 21.455 16.1 16.1 0 0121.455 37.53z"
                    fill="#fb3"
                />
                <path
                    data-name="Path 34051"
                    d="M37.531 21.455A16.1 16.1 0 0021.455 5.379V37.53a16.1 16.1 0 0016.076-16.075z"
                    fill="#e68a2e"
                />
            </g>
            <g data-name="Layer 2">
                <g data-name="Layer 6">
                    <g data-name="Group 13271">
                        <path
                            data-name="Path 21420"
                            d="M32.033 22.641a11.306 11.306 0 11-22.611 0c0-6.3 11.306-14.494 11.306-14.494s11.305 8.188 11.305 14.494z"
                            fill="#ffe14d"
                        />
                        <path
                            data-name="Path 21421"
                            d="M29.384 25.362c0 3.7-3.877 7.092-8.656 7.092s-8.665-3.392-8.665-7.092 3.957-8.965 8.744-8.965 8.577 5.264 8.577 8.965z"
                            fill="#ffca6c"
                        />
                        <path
                            data-name="Path 21422"
                            d="M20.723 12.696s9.186 6.66 11 12.586a11.543 11.543 0 00.309-2.641c.001-6.306-11.309-14.494-11.309-14.494s-11.3 8.188-11.3 14.494a11.075 11.075 0 00.309 2.641c1.81-5.927 10.991-12.586 10.991-12.586z"
                            fill="#ffe14d"
                            opacity={0.8}
                        />
                    </g>
                    <path
                        data-name="Path 34052"
                        d="M25.895 19.913H15.552a.618.618 0 00-.618.618v.927a.618.618 0 00.618.627h3.445a.618.618 0 01.627.618v.018l-.318 10.652a.627.627 0 00.583.645l.839.035.839-.035a.627.627 0 00.574-.645l-.307-10.652a.609.609 0 01.6-.636h3.462a.627.627 0 00.627-.627v-.927a.618.618 0 00-.627-.618z"
                        fill="#fff"
                    />
                </g>
            </g>
        </svg>
    )
}

export default SvgComponent
