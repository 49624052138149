const getTeacherAppRoute = ({ isTimeTableEnabled = false, isClassroomEnabled = false }) => {
    const TEACHER_APP = [
        // { title: 'Dashboard', isActive: true, iconType: 'dashboard', route: '/sessions' },
        { title: 'Classrooms', isActive: true, iconType: 'classroom', route: '/classrooms',allowedRoles:['mentor','schoolAdmin','schoolTeacher'] },
        { title: 'Reports', isActive: true, iconType: 'reports', route: '/reports',allowedRoles:['mentor','schoolAdmin','schoolTeacher'] },
        // { title: 'Students', isActive: true, iconType: 'timeTable', route: '/students',allowedRoles:['schoolAdmin'] }
        // { title: 'Session Embed', isActive: true, iconType: 'classroom', route: '/session-embed', },
        // { title: 'Switch to student app', isActive: true, iconType: 'timeTable', route: '/sessions' },
        // { title: 'Students', isActive: false, iconType: 'student', route: '/sessions' },
        // { title: 'Course', isActive: false, iconType: 'course', route: '/sessions' },
        // { title: 'Reports', isActive: true, iconType: 'reports', route: '/sessions' },
        // { title: 'Settings', isActive: true, iconType: 'settings', route: '/sessions' },
        // { title: 'Contact Us', isActive: true, iconType: 'contact', route: '/sessions' }
    ]
    return TEACHER_APP
}

const STUDENT_APP = [{ title: 'Dashboard', isActive: true, iconType: 'classroom', route: '/sessions' },
{ title: 'Classroom', isActive: true, iconType: 'classroom', route: '/sessions' },
{ title: 'Homework', isActive: true, iconType: 'classroom', route: '/sessions' },
{ title: 'My Teacher', isActive: false, iconType: 'classroom', route: '/sessions' },]

export { getTeacherAppRoute, STUDENT_APP }